import React, { useEffect } from 'react';
import {useTranslation} from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import {getLocation} from '../../../Routes';
import {useHistory} from 'react-router';
import {Grid, Typography} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {backendsListSelector} from '../../../core/backends/selectors';
import {BackendsTypes, QUANTUM, SIMULATOR} from '../../Services/consts';
import {meSelector} from '../../../core/users/selectors';
import PropTypes from 'prop-types';

const useStyles = makeStyles( theme => ({
  root: {
    width: '100%',
    border: '2px solid ' + theme.palette.grey[300],
    borderRadius: 7,
    height: '15.5em'
  },
  container: {
    padding: theme.spacing(1.5, 3, 3, 3)
  },
  item: {
    padding: theme.spacing(1.5, 0),
    color: theme.palette.grey[700]
  },
  icon: {
    width: 16,
    fill: theme.palette.info.main,
    stroke: theme.palette.info.main,
    margin: theme.spacing(0, 1, 1, 1),
    strokeWidth: '0.2px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  header: {
    fontSize: 22,
    color: theme.palette.grey[600],
    padding: theme.spacing(1.5, 0, 1.5, 0)
  },
  topContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  button: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.secondary.main,
    textTransform: 'inherit',
    boxShadow: 'inherit',
    fontSize: 16,
    transition: theme.transitions.create('color', {
      duration: theme.transitions.duration.enteringScreen
    }),
    '&:hover': {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.info.main,
      boxShadow: 'inherit',
    }
  },
  description: {
    fontSize: 14,
    padding: theme.spacing(1),
    color: theme.palette.grey[800],
    fontWeight: 600
  },
  wrapper: {
    padding: theme.spacing(1),
    marginTop: '24px'
  },
  count: {
    fontSize: 44,
    padding: theme.spacing(0, 0, 1, 0),
    color: theme.palette.info.main
  },
  countText: {
    fontSize: 12,
    color: theme.palette.grey[800],
    fontWeight: 600,
    maxWidth: 170
  }
}))

const ComputingServices = (props) => {
  const { items, isFetched: backendsIsFetched } = useSelector(backendsListSelector)
  const { payload, isFetched: meIsFetched } = useSelector(meSelector)
  const classes = useStyles();
  const {t} = useTranslation();
  const loc = getLocation();
  const history = useHistory();
  const backends = items.filter(i => payload.backends.indexOf(i.id) !== -1)
  const quantumCount = backends.filter(i => i.paradigm === BackendsTypes[QUANTUM]).length;
  const simulatorCount = backends.filter(i => i.paradigm === BackendsTypes[SIMULATOR]).length;
  const numberServicesCount = backends.length;

  useEffect(() => {
    if (backendsIsFetched && meIsFetched) props.onLoad()
  }, [backendsIsFetched, meIsFetched]);

  return (
    <div
      id='services-root'
      className={classes.root}
      ref={props.r}
    >
      <div className={classes.container}>
        <div className={classes.topContainer}>
          <div className={classes.header}>
            {t('Computing services')}
          </div>
          <Button
            className={classes.button}
            onClick={()=>{history.push(loc.Services())}}
          >
            {t('All services')}
          </Button>
        </div>
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
          >
            <Typography className={classes.description}>
              {t('servicesDescription')}
            </Typography>
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            sm={4}
            xs={4}
          >
            <div className={classes.wrapper}>
              <div className={classes.count}>{quantumCount}</div>
              <div className={classes.countText}>{t('Available quantum systems')}</div>
            </div>
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            sm={4}
            xs={4}
          >
            <div className={classes.wrapper}>
              <div className={classes.count}>{simulatorCount}</div>
              <div className={classes.countText}>{t('Available simulator')}</div>
            </div>
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            sm={4}
            xs={4}
          >
            <div className={classes.wrapper}>
              <div className={classes.count}>{numberServicesCount}</div>
              <div className={classes.countText}>{t('Total number of computing services')}</div>
            </div>
          </Grid>

        </Grid>

      </div>
    </div>
  );
}

ComputingServices.propTypes = {
  onLoad: PropTypes.func,
  r: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })])
};

export default ComputingServices;

import React from 'react';
import PropTypes from 'prop-types';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {useStyles} from './styles';
import {getMenuItem} from './utils';
import {getLocation} from '../../../Routes';
import {useHistory} from 'react-router';
import {ReactComponent as ProfileIcon} from '../../../components/icons/user.svg';
import {ReactComponent as HelpIcon} from '../../../components/icons/help.svg';
import LanguageSelector from '../../../components/LanguageSelectors/LanguageSelector';

const BottomSidebarNav = props => {
  const { ...rest } = props;
  const {t} = useTranslation();
  const loc = getLocation();
  const history = useHistory();
  const classes = useStyles();
  const pages = [
    getMenuItem('Profile', t('profile'), props => (
      <ProfileIcon
        className={classes.iconItem}
        {...props}
      />
    ), loc, history, classes),
    getMenuItem('Help', t('help'), props => (
      <HelpIcon
        className={classes.iconItem}
        {...props}
      />
    ), loc, history, classes)
  ];
  return (
    <List
      {...rest}
      className={classes.root}
    >
      <LanguageSelector />
      {pages.map(page => (
        <ListItem
          className={classes.item}
          disableGutters
          key={page.key}
          onClick={page.href}
        >
          <ListItemIcon className={classes.listItem}>{page.icon}</ListItemIcon>
          <ListItemText className={classes.listText}>{page.title}</ListItemText>
        </ListItem>
      ))}
    </List>
  );

};

BottomSidebarNav.propTypes = {
  className: PropTypes.string
};

export default BottomSidebarNav;

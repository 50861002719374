import * as CONSTS from './consts';

export const fetchAction = () => ({
  type: CONSTS.FETCH_LIST
});

export const createAction = ({ name, fields }) => {
  return {
    type: CONSTS.FETCH_CREATE,
    payload: { name, fields }
  };
};

export const deleteAction = id => ({
  type: CONSTS.DELETE,
  payload: { id }
});

export const updateAction = ({ id, name, fields }) => {
  const data = { id, name, fields };
  return {
    type: CONSTS.UPDATE,
    payload: { id, data }
  };
};

export const updateList = items => ({
  type: CONSTS.LIST,
  payload: [...items]
});

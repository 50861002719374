import React from 'react';
import BaseStatus from './BaseStatus';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import styles from './index.module.css';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

const Pending = ({ fill }) => {
  const {t} = useTranslation();
  return (
    <BaseStatus
      title={t('PENDING')}
      fill={!!fill}
      className={styles.pending}
      fillClassName={styles.pendingFill}>
      <DonutLargeIcon />
    </BaseStatus>
  );
};

Pending.propTypes = {
  fill: PropTypes.bool,
};

export default Pending;

import React from 'react';
import {useTranslation} from 'react-i18next';
import {useStyles} from './styles';
import {ReactComponent as Icon} from '../../../components/icons/help.svg';


const HelpTitle = () => {
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <div className={classes.topText}>
      <Icon className={classes.topIcon}/>
      <span className={classes.topSpan}>{t('help')}</span>
    </div>
  );
}

export default HelpTitle;
import React from 'react';
import BaseStatus from './BaseStatus';
import StarsIcon from '@material-ui/icons/Stars';
import styles from './index.module.css';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

const Created = ({ fill }) => {
  const {t} = useTranslation();
  return (
    <BaseStatus
      title={t('CREATED')}
      fill={!!fill}
      className={styles.created}
      fillClassName={styles.createdFill}>
      <StarsIcon />
    </BaseStatus>
  );
};

Created.propTypes = {
  fill: PropTypes.bool,
};

export default Created;

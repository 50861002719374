import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import {Dialog, DialogContent, DialogTitle} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {ReactComponent as CloseIcon} from '../../../components/icons/close.svg';
import {ReactComponent as NewIcon} from '../../../components/icons/new.svg';
import {Form} from 'react-final-form';
import {getValidators, isRequired} from '../../../utils/validators';
import {useResponsive} from '../../../utils/hooks';
import {ReactComponent as SaveIcon} from '../../../components/icons/save.svg';
import Typography from '@material-ui/core/Typography';
import {String} from '../../../components/FormFields';
import BasicDatePicker from '../../../components/FormFields/DatePickerField';
import moment from "moment";
import {createNews} from "../../../core/api";
import {useDispatch} from "react-redux";
import {showAlertMessageAction} from "../../../core/alerts/actions";


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-end'
  },
  closeIcon: {
    strokeWidth: '0.2px',
    stroke: theme.palette.secondary.main,
    fill: theme.palette.secondary.main,
    width: '18px'
  },
  accessHeader: {
    color: theme.palette.info.main,
    display: 'flex',
    fontSize: '28px',
    letterSpacing: 0,
    marginBottom: theme.spacing(5),
    whiteSpace: 'nowrap',
    alignItems: 'center'
  },
  accessHeaderMobile: {
    fontSize: '21px'
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(0.5, 1),
    fontWeight: 600
  },
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: 0,
      border: '1px solid ' + theme.palette.grey[300]
    }
  },
  dialogContent: {
    padding: theme.spacing(1, 5, 4)
  },
  dialogContentMobile: {
    padding: theme.spacing(1, 2, 4)
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
    '& > div': {
      marginBottom: theme.spacing(0),
      marginTop: theme.spacing(0)
    },
    '& label.Mui-focused, label': {
      color: theme.palette.primary.main
    },
    '& label.MuiInputLabel-shrink': {
      transform: 'translate(14px, -15px) scale(0.75)'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.grey[300]
      },
      '&:hover fieldset': {
        borderColor: theme.palette.grey[400]
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.grey[400]
      },
      '& input': {
        color: theme.palette.primary.main
      },
      '& textarea': {
        color: theme.palette.primary.main
      }
    }
  },
  submit: {
    margin: theme.spacing(1, 0, 1),
    float: 'right',
    textTransform: 'inherit'
  },
  submitRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  icon: {
    width: 40,
    height: 40,
    strokeWidth: '0.2px',
    stroke: theme.palette.secondary.main,
    fill: theme.palette.secondary.main,
    marginRight: theme.spacing(3)
  },
  submitIcon: {
    width: 22,
    fill: theme.palette.secondary.contrastText,
    strokeWidth: '0.2px',
    stroke: theme.palette.secondary.contrastText
  },
  container: {
    padding: theme.spacing(0)
  },
  mobileContainer: {
    padding: 0 + '!important'
  },
  button: {
    textTransform: 'inherit',
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(0.5)
  },
  buttonIcon: {
    width: 22,
    fill: theme.palette.secondary.main,
    strokeWidth: '0.2px',
    stroke: theme.palette.secondary.main
  },
  wrapper: {
    padding: theme.spacing(2, 0, 0.5, 0)
  }
}))

const validators = getValidators({
  date: [isRequired],
  news: [isRequired]
});

export const useNotifications = () => {
  const dispatch = useDispatch()
  const onResult = () => {
    dispatch(showAlertMessageAction({
      text: 'Create new success',
      severity: 'success'
    }))
  }

  const onError = e =>  dispatch(showAlertMessageAction({
    text: e.message,
    severity: 'error'
  }))
  return { onResult, onError }
}

const NewNewsModal = ({ onAdd }) => {
  const { onResult, onError } = useNotifications()
  const classes = useStyles();
  const {t} = useTranslation();
  const {isMobile} = useResponsive();
  const [open, setOpen] = useState(false);
  const toggleOpen = () =>{setOpen(!open)};
  const [error] = useState('')

  const handleClickOpen = () => {
    toggleOpen();
  };

  const handleClose = () => {
    toggleOpen();
  };

  const onSubmit = (props) => {
    const dt = moment(props.date).format('YYYY-MM-DD')
    const title = props.news
    createNews({dt, title}).then(r => {
      onAdd(r.data)
      onResult()
    }).catch(onError)
    toggleOpen();
  };

  return (
    <div className={clsx(classes.root)}>
      <Button
        className={classes.button}
        onClick={handleClickOpen}
        startIcon={<NewIcon className={classes.buttonIcon} />}
      >
        {t('Add news')}
      </Button>

      <Dialog
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        className={classes.dialog}
        fullWidth
        maxWidth={'sm'}
        onClose={handleClose}
        open={open}
      >
        <DialogTitle
          className={classes.header}
          disableTypography
          id="add-news"
        >
          <IconButton onClick={handleClose}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          className={
            clsx(
              classes.dialogContent,
              isMobile && classes.dialogContentMobile)
          }
        >
          <div
            className={
              clsx(
                classes.accessHeader,
                isMobile && classes.accessHeaderMobile
              )}
          >
            <NewIcon className={classes.icon}/>
            {t('Add news')}
          </div>
          <div className={clsx(classes.container, isMobile && classes.mobileContainer)}>
            <Form
              initialValues={{ date: new Date() }}
              onSubmit={onSubmit}
              render={({ handleSubmit }) => (
                <form
                  className={clsx(classes.form)}
                  onSubmit={handleSubmit}
                >
                  <div className={classes.wrapper}>
                    <BasicDatePicker
                      fullWidth
                      label={t('Date')}
                      margin="dense"
                      name="date"
                    />

                    <String
                      fullWidth
                      label={t('News')}
                      margin="dense"
                      multiline
                      name="news"
                      rows={8}
                    />

                    <Typography color="error">{error}</Typography>
                  </div>
                  <div className={classes.submitRow}>
                    <Button
                      className={classes.submit}
                      color="primary"
                      startIcon={<SaveIcon className={classes.submitIcon} />}
                      type="submit"
                      variant="contained"
                    >
                      {t('Save')}
                    </Button>
                  </div>
                </form>
              )}
              validate={validators}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

NewNewsModal.propTypes = {}

export default NewNewsModal;

import { SAVE_MESSAGES, DOMAIN, MESSAGES } from './consts'
import {combineReducers} from "redux";

const typeToName = {
  10: 'LOG',
  20: 'STATUS',
  30: 'METRIC',
  40: 'UTILIZATION',
  50: 'RESULT',
  60: 'MESSAGE',
  70: 'TRACK',
}

const formatLog = ({ task_id, user_id, payload, type }) => {
  const user = user_id ? `user_id: ${user_id}` : null
  const task = task_id ? `task_id: ${task_id}`: null
  return [typeToName[type] || 'UNKNOWN', user, task, JSON.stringify(payload)].filter( i => i).join(' - ')
}

const messagesReducer =  (state = [], action) => {
  switch (action.type) {
    case SAVE_MESSAGES:
      return [formatLog(action.payload), ...state]
    default:
      return state
  }
}

export default {
  [DOMAIN]: combineReducers({
    [MESSAGES]: messagesReducer
  })
}
import * as CONSTS from './consts';
import set from 'lodash/set'

export const fetchMeAction = () => ({
  type: CONSTS.FETCH_ME
});

export const fetchLoginAction = (username, password) => ({
  type: CONSTS.LOGIN,
  payload: { username, password }
});

export const fetchLogoutAction = () => ({
  type: CONSTS.LOGOUT
});

export const fetchUsers = (page, pageSize) => ({
  type: CONSTS.LIST,
  payload: { pageSize, page: page || 1 }
});

export const createUser = ({
  email,
  password,
  first_name,
  last_name,
  username,
  meta,
  quota_group
}) => {
  const data = { username, password, email };
  if (first_name) data.first_name = first_name;
  if (last_name) data.last_name = last_name;
  if (meta) data.meta = meta;
  if (quota_group) data.quota_group = quota_group;

  return {
    type: CONSTS.FETCH_CREATE,
    payload: data
  };
};

export const fetchUser = id => ({
  type: CONSTS.CURRENT,
  payload: { id }
});

export const deleteUser = id => ({
  type: CONSTS.DELETE,
  payload: { id }
});

export const updateUser = ({
  id,
  email,
  password,
  first_name,
  last_name,
  userName,
  meta,
  quota_group
}) => {
  const data = { id, email };
  if (password) data.password = password;
  if (email) data.email = email;
  if (first_name) data.first_name = first_name;
  if (last_name) data.last_name = last_name;
  if (userName) data.username = userName;
  if (meta) data.meta = meta;
  if (quota_group) data.quota_group = quota_group;

  return {
    type: CONSTS.UPDATE,
    payload: { id, data }
  };
};

export const updatePasswordUser = ({
  id,
  password,
}) => {
  return {
    type: CONSTS.UPDATE,
    payload: { id, data: { password } }
  };
};

export const updateMetaUserFiled = ({
  id,
  field,
  value,
  meta
}) => {
  set(meta, field, value)
  return {
    type: CONSTS.UPDATE,
    payload: { id, data: { meta } }
  };
};

export const updateToken = id => ({
  type: CONSTS.UPDATE_TOKEN,
});

export const sendRequest = request => ({
  type: CONSTS.SEND_REQUEST,
  payload: { kind: request }
});

export const fetchUserGroups = () => ({
  type: CONSTS.FETCH_GROUPS,
  payload: {}
});
import * as CONSTS from './consts';

export const createTask = (backendId, matrixIds, params) => {
  const { blocking, ...param_ } = params
  return {
    type: CONSTS.FETCH_CREATE,
    payload: {
      backend: backendId,
      tensors: matrixIds,
      params: param_,
      blocking: blocking || false
    }
  };
};

export const bulkCreateTask = tasks => {
  return {
    type: CONSTS.FETCH_BULK_CREATE,
    payload: tasks
  };
};

export const fetchTasks = (
  page,
  pageSize,
  userId,
  serverId,
  statusFilter,
  backendFilter
) => ({
  type: CONSTS.FETCH_LIST,
  payload: {
    pageSize,
    page: page || 1,
    userId,
    serverId,
    statusFilter,
    backendFilter
  }
});

export const fetchTask = id => ({
  type: CONSTS.FETCH_CURRENT,
  payload: { id }
});

export const stopTask = id => ({
  type: CONSTS.FETCH_STOP,
  payload: { id }
});

export const updateList = (results, count, pageSize) => ({
  type: CONSTS.LIST,
  payload: { results, count, page_size: pageSize }
});

export const updateCurrent = (payload, data) => ({
  type: CONSTS.CURRENT,
  payload: { ...payload, ...data }
});

export const filterTasks = (datasets, templates) => ({
  type: CONSTS.FETCH_FILTERED,
  payload: { datasets, templates }
});

export const aggregatedTasks = (datasets, backends, metric) => ({
  type: CONSTS.FETCH_AGGREGATED,
  payload: { datasets, backends, metric }
});

export const tveTasks = (datasets, templates) => ({
  type: CONSTS.FETCH_TVE,
  payload: { datasets, templates }
});

import React from 'react';
import BaseStatus from './BaseStatus';
import styles from './index.module.css';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

const Queue = ({ fill }) => {
  const {t} = useTranslation();
  return (
    <BaseStatus
      title={t('QUEUE')}
      fill={!!fill}
      className={styles.waiting}
      fillClassName={styles.waitingFill}>
      <HourglassEmptyIcon />
    </BaseStatus>
  );
};

Queue.propTypes = {
  fill: PropTypes.bool,
};

export default Queue;

import React from 'react';
import BaseStatus from './BaseStatus';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import styles from './index.module.css';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

const Canceled = ({ fill }) => {
  const {t} = useTranslation();
  return (
    <BaseStatus
      title={t('CANCELED')}
      fill={!!fill}
      className={styles.canceled}
      fillClassName={styles.canceledFill}>
      <HighlightOffIcon />
    </BaseStatus>
  );
};

Canceled.propTypes = {
  fill: PropTypes.bool,
};

export default Canceled;

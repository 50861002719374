import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Logo from '../Icons/Logo';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import LanguageSelector from '../LanguageSelectors/LanguageSelector';
import OrderConsultation from '../OrderConsultation';
import SignInButton from '../SignInButton';
import PropTypes from 'prop-types';
import SignUpButton from '../SignUpButton';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',

  },
  header: {
    padding: theme.spacing(2, 5),
    boxShadow: 'none'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  right: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const TopBar = ({type}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar
        className={classes.header}
        position="absolute"
      >
        <Toolbar className={classes.toolbar}>
          <Logo
            height={50}
            title
          />
          <div className={classes.right}>
            <LanguageSelector position={'top'}/>
            {type === 'sign-up' && <SignInButton />}
            {type === 'sign-in' && <SignUpButton />}
            <OrderConsultation />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

TopBar.propTypes = {
  type: PropTypes.string.isRequired
};

export default TopBar;

import * as CONSTS from './consts';
import { getDomainSelector } from '../utils/fetch';
import { getListPayload } from '../../utils/paginator';
import get from 'lodash/get'

const listPath = [CONSTS.DOMAIN, CONSTS.LIST_NAME];
const currentPath = [CONSTS.DOMAIN, CONSTS.CURRENT_NAME];

const _listSelector = getDomainSelector(listPath, null);
const _currentSelector = getDomainSelector(currentPath, null);

export const serverListSelector = state => {
  const { payload, isFetching, isFetched } = _listSelector(state);
  return { isFetching, isFetched, ...getListPayload(payload || {}) };
};

export const serverSelector = getDomainSelector(currentPath, {});

export const serverUtilization = state => {
  const { payload } = _currentSelector(state);
  const getter = (name, isFloat) => {
    return (get((payload || {}).utilization, name, isFloat ? 0.0 : 0)) || (isFloat ? 0.0 : 0)
  }
  return {
    memTotal: getter('mem_total'),
    memUsage: getter('mem_usage'),
    memUsagePercent: getter('mem_usage_percent', true),
    cpuSystem: getter('cpu_system', true),
    cpuUser: getter('cpu_user', true),
    gpuMemFree: getter('gpu_mem_free'),
    gpuMemTotal: getter('gpu_mem_total'),
    gpuUtilization: getter('gpu_utilization', true),
    bootTime: getter('boot_time')
  }
}

import React from 'react';
import {useTranslation} from 'react-i18next';
import {useStyles} from './styles';
import {ReactComponent as NewIcon} from '../../../components/icons/new.svg';


const NewTaskTitle = () => {
  const classes = useStyles();
  const {t} = useTranslation();
  return (
    <div className={classes.topText}>
      <NewIcon className={classes.topIcon} />
      <span className={classes.topSpan}>{t('newTask')}</span>
    </div>
  );
}

export default NewTaskTitle;

import * as CONSTS from './consts';

export const fetchAction = () => ({
  type: CONSTS.FETCH_LIST
});

export const createAction = ({ name, label, place_holder, type, items }) => {
  return {
    type: CONSTS.FETCH_CREATE,
    payload: {
      name,
      label,
      place_holder,
      type,
      items
    }
  };
};

export const deleteAction = id => ({
  type: CONSTS.DELETE,
  payload: { id }
});

export const updateAction = ({
  id,
  name,
  label,
  place_holder,
  type,
  items
}) => {
  const data = {
    id,
    name,
    label,
    place_holder,
    type,
    items
  };
  return {
    type: CONSTS.UPDATE,
    payload: { id, data }
  };
};

export const updateList = items => ({
  type: CONSTS.LIST,
  payload: [...items]
});

import React, {useRef, useEffect, useState} from 'react';
import {AutoSizer} from 'react-virtualized';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import RecentTasks from './components/RecentTasks';
import ComputingServices from './components/ComputingServices';
import ApiKey from './components/ApiKey';
import News from './components/News';
import clsx from 'clsx';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles( theme => ({
  wrapper: {
    padding: theme.spacing(4),
    boxSizing: 'border-box',
    overflow: 'auto'
  },
  loaderShow: {
    background: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
  },
  loaderHide: {
    display: 'none'
  },
  contentHide: {
    visibility: 'hidden'
  }
}))

const Dashboard = () => {
  const classes = useStyles();
  const recentRef = useRef();
  const computingRef = useRef();
  const apiRef = useRef();
  const [heights, setHeights] = useState({})

  const [tasksFetched, setTasksFetched] = useState(false);
  const [servicesFetched, setServicesFetched] = useState(false);
  const [newsFetched, setNewsFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const recentHeight = recentRef.current ? recentRef.current.clientHeight : 0;
    const computingHeight = computingRef.current ? computingRef.current.clientHeight : 0;
    const apiHeight = apiRef.current ? apiRef.current.clientHeight : 0;
    setHeights({
      recent: recentHeight,
      computing: computingHeight,
      api: apiHeight
    })
  }, [recentRef.current, computingRef.current, apiRef.current])

  useEffect(() => {
    setIsLoading(!tasksFetched || !newsFetched || !servicesFetched)
  }, [tasksFetched, newsFetched, servicesFetched]);

  return (
    <AutoSizer>
      {({ width, height }) => (
        <div
          className={classes.wrapper}
          style={{ width, height }}
        >
          <Grid
            container
            spacing={4}
            className={clsx(isLoading && classes.contentHide)}
          >
            <Grid
              item
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <RecentTasks r={recentRef} onLoad={() => setTasksFetched(true)}/>
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <ComputingServices r={computingRef} onLoad={() => setServicesFetched(true)}/>
                </Grid>
              </Grid>

            </Grid>
            <Grid
              item
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <ApiKey r={apiRef}/>
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <News heights={heights} onLoad={() => setNewsFetched(true)} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className={clsx(classes.loaderShow, !isLoading && classes.loaderHide)}>
            <CircularProgress />
          </div>
        </div>
      )}
    </AutoSizer>
  )
}

export default Dashboard;

export const DOMAIN = 'MATRIX';
export const UPLOAD = `${DOMAIN}@UPLOAD`;
export const CURRENT_NAME = 'CURRENT';
export const LIST_NAME = 'LIST';
export const FETCH = `${DOMAIN}@FETCH`;
export const CURRENT = `${DOMAIN}@${CURRENT_NAME}`;
export const LIST = `${DOMAIN}@${LIST_NAME}`;
export const UPDATE = `${DOMAIN}@$UPDATE`;
export const DELETE = `${DOMAIN}@$DELETE`;
export const FETCH_CURRENT = `${DOMAIN}@CURRENT`;
export const BULK_DELETE = `${DOMAIN}@$BULK_DELETE`;
export const FETCH_LIST = `${DOMAIN}@LIST`;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {useTranslation} from 'react-i18next';
import {css} from '../../const';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import grey from '@material-ui/core/colors/grey';
import Support from "./Support";
import {useHistory} from "react-router";
import {toPrivacyPolicy, toTermOfUse} from "../../Routes";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  appBar: {
    boxShadow: 'none',
    color: theme.palette.text.primary,
    width: `calc(100% - ${css.drawerClosedWidth}px - ${css.drawerBorderWidth}px)`,
    backgroundColor: theme.palette.secondary.contrastText,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    '& > .MuiToolbar-regular': {
      height: css.bottomBarHeight,
      minHeight: 'inherit'
    },
    top: 'inherit',
    bottom: 0
  },
  appBarShift: {
    marginLeft: css.drawerWidth,
    width: `calc(100% - ${css.drawerWidth}px - ${css.drawerBorderWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  toolbar: {
    fontSize: '12px',
    color: theme.palette.secondary.main
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    color: grey[500],
    width: '100%',
    alignItems: 'center',
    borderTop: `2px dashed ${grey[300]}`
  },
  left: {
    display: 'flex'
  },
  right: {
    display: 'flex'
  },
  rightItem: {
    padding: theme.spacing(0, 1),
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

const BottomBar = ({ open }) => {
  const classes = useStyles();
  const history = useHistory()
  const {t} = useTranslation();

  return (
    <div className={classes.root}>
      <AppBar
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
        position="absolute"
      >
        <Toolbar className={classes.toolbar}>
          <div className={classes.container}>
            <div className={classes.left}>
              <div className={classes.rightItem} onClick={() => {history.push(toTermOfUse())}}>{t('termsOfUse')}</div>
              <div className={classes.rightItem} onClick={() => {history.push(toPrivacyPolicy())}}>{t('privacyPolicy')}</div>
            </div>
            <div className={classes.right}>
               <Support />
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

BottomBar.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default BottomBar;

import React from 'react';
import {useTranslation} from 'react-i18next';
import {useStyles} from './styles';
import {ReactComponent as TasksIcon} from '../../../components/icons/tasks.svg';
import {useParams} from "react-router";


const TasksTitle = () => {
  const classes = useStyles();
  const { id } = useParams()
  const {t} = useTranslation();
  return (
    <div className={classes.topText}>
      <TasksIcon className={classes.topIcon} />
      <span className={classes.topSpan}>{id ? `${t('task')} ${id}` : t('tasks')}</span>
    </div>
  );
}

export default TasksTitle;

import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import {getStatuses} from '../../../components/tasks/status';
import {useTranslation} from 'react-i18next';
import {toTask} from '../../../Routes';
import {getStatusCell} from '../../../utils/tools';

const statuses = getStatuses(false);

const TaskRow = props => {
  const {
    id,
    created,
    status,
    classes,
    end,
  } = props;

  const {t} = useTranslation();

  const metrics = props.metrics || [];

  const {dateCell} = getStatusCell(metrics, created, status, t, end);

  return (
    <React.Fragment>
      <TableRow
        className={classes.row}
        hover
        onClick={() => toTask(id)}
      >
        <TableCell className={classes.statusContainer}>
          {statuses[status]} <div className={classes.taskName}>{t('task')} {id}</div>
        </TableCell>

        {dateCell}
      </TableRow>
    </React.Fragment>
  );
};

TaskRow.propTypes = {
  classes: PropTypes.object.isRequired,
  created: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  metrics: PropTypes.array,
  status: PropTypes.number.isRequired,
  tensor: PropTypes.string
};

export default TaskRow;

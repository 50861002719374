import { getFetchSaga, successSaga } from '../utils/fetch';
import { put, takeLatest } from 'redux-saga/effects';
import * as CONSTS from './consts';
import * as api from '../api';
import { getLocation } from '../../Routes';
import { history } from '../../commons';
import { showAlertMessageAction } from '../alerts/actions';
import { select, takeEvery } from '@redux-saga/core/effects';
import { updateList } from './actions';
import { listSelector } from './selectors';
import { listSelector as ls } from '../formFields/selectors';
import { findIndex } from '../../utils/tools';

const listSaga = {
  type: CONSTS.LIST,
  apiMethod: api.listBackendForms
};

const addItemToState = function*(_item) {
  const state = yield select(listSelector);
  const fieldsState = yield select(ls);
  let items = state.items || [];
  const result = items.slice();
  const fieldsIds = _item.fields;
  const fields = fieldsIds.map(id => fieldsState.itemsById[id]);
  const item = { ..._item, fields };
  result.push(item);
  yield successSaga(updateList([...result]));
};

const createSaga = {
  type: CONSTS.CURRENT,
  apiMethod: api.createBackendForms,

  handleSuccess: function*(e) {
    const item = e && e.data;
    const name = item && item.name;
    const message = {
      text: `Backend params "${name}" created successfully .`,
      severity: 'info'
    };

    yield addItemToState(item);

    const loc = getLocation();
    history.push(loc.ListParams());

    yield put(showAlertMessageAction(message));

    return e;
  },
  handleError: function*(e) {
    const message = {
      text:
        'An error occurred while creating the backend params, try again please.',
      severity: 'error'
    };
    yield put(showAlertMessageAction(message));

    return e;
  }
};

const deleteFromState = function*(itemId) {
  const state = yield select(listSelector);
  let items = state.items || [];
  const itemIndex = findIndex(items, { id: itemId });
  if (itemIndex !== -1) {
    items.splice(itemIndex, 1);
    yield successSaga(updateList([...items]));
  }
};

const deleteSaga = {
  type: CONSTS.FETCH_CURRENT,
  apiMethod: api.deleteBackendForms,
  handleSuccess: function*(e) {
    const message = {
      text: 'Item params deleted successfully.',
      severity: 'info'
    };

    yield deleteFromState(e.data);

    const loc = getLocation();
    history.push(loc.ListParams());

    yield put(showAlertMessageAction(message));
    return { data: null };
  }
};

const updateFromState = function*(item) {
  const formState = yield select(listSelector);
  const fieldsState = yield select(ls);
  let items = formState.items || [];
  const itemIndex = findIndex(items, { id: item.id });
  if (itemIndex !== -1) {
    const result = items.slice();
    const fieldsIds = item.fields;
    const fields = fieldsIds.map(id => fieldsState.itemsById[id]);
    result[itemIndex] = { ...item, fields: fields };
    yield successSaga(updateList([...result]));
  }
};

const updateSaga = {
  type: CONSTS.FETCH_CURRENT,
  apiMethod: api.updateBackendForms,
  handleSuccess: function*(e) {
    const item = e && e.data;
    const name = item && item.name;
    const message = {
      text: `Backend params "${name}" updated successfully .`,
      severity: 'info'
    };

    yield updateFromState(item);

    const loc = getLocation();
    history.push(loc.ListParams());

    yield put(showAlertMessageAction(message));
    return { data: e.data };
  }
};

export default function*() {
  yield takeLatest(CONSTS.FETCH_LIST, getFetchSaga(listSaga));
  yield takeLatest(CONSTS.FETCH_CREATE, getFetchSaga(createSaga));
  yield takeEvery(CONSTS.DELETE, getFetchSaga(deleteSaga));
  yield takeLatest(CONSTS.UPDATE, getFetchSaga(updateSaga));
}

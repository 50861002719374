import React from 'react';
import PropTypes from 'prop-types';
import {
  TableCell
} from '@material-ui/core';

import { useStyles } from './styles';


const DateCell = ({at, dt, text}) => {
  const classes = useStyles();

  return (
    <TableCell className={classes.taskDate}>
      {text} {dt.format('MM.DD.YYYY')} {at} {dt.format('HH:mm:ss')}
    </TableCell>
  );
};

DateCell.propTypes = {
  at: PropTypes.string.isRequired,
  dt: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired
};

export default DateCell;

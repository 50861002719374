import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ResponsiveContainer, XAxis, Tooltip, CartesianGrid } from 'recharts';
import AreaChart from 'recharts/lib/chart/AreaChart';
import Area from 'recharts/lib/cartesian/Area';
import YAxis from 'recharts/lib/cartesian/YAxis';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  tooltip: {
    padding: theme.spacing(2),
    background: '#ffffff',
    border: '1px solid #E0E0E0',
    '& span': {
      fontWeight: 'bold'
    }
  }
}));

const EnergyChart = ({ items, height }) => {
  const classes = useStyles();
  return (
    <div style={{ height, width: '100%' }}>
      <ResponsiveContainer>
        <AreaChart
          data={items}
          baseValue="dataMin"
          margin={{
            top: 20,
            right: 0,
            bottom: 20,
            left: 0
          }}>
          <CartesianGrid strokeDasharray="6 6" />
          <XAxis
            dataKey="time"
            tickFormatter={value => moment(value).format('DD MMM HH:mm')}
          />
          <YAxis />
          <Tooltip
            content={({ payload, label }) => {
              if (!payload || !payload.length) {
                return null;
              } else {
                return (
                  <div className={classes.tooltip}>
                    <div>
                      <span>Solver logs</span>:{' '}
                      {moment(label).format('HH:mm:ss.SSS')}
                    </div>
                    <div>
                      <span>Energy</span>: {payload.length && payload[0].value}
                    </div>
                  </div>
                );
              }
            }}
          />
          <Area
            dataKey="energy"
            stroke="#86D0F3"
            fill="rgba(134, 208, 243, 0.1)"
            strokeWidth={2}
            isAnimationActive={false}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

EnergyChart.propTypes = {
  height: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      time: PropTypes.string,
      energy: PropTypes.number,
      payload: PropTypes.object
    })
  )
};

export default EnergyChart;

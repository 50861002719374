import { getFetchSaga } from '../utils/fetch';
import { put, takeLatest } from 'redux-saga/effects';
import * as CONSTS from './consts';
import * as api from '../api';
import { showAlertMessageAction } from '../alerts/actions';
import { fetchDatasets } from './actions';
import { getLocation } from '../../Routes';
import { history } from '../../commons';

const getPageData = () => {
  let location = window.location;
  const params = new URLSearchParams(location.search);
  const page = params.get('page') || 1;
  const pageSize = params.get('pageSize') || 10;
  return { page, pageSize };
};

const listDatasets = {
  type: CONSTS.LIST,
  apiMethod: api.listDatasets
};

const createDataset = {
  type: CONSTS.CURRENT,
  apiMethod: api.createDataset,

  handleSuccess: function*(e) {
    const name = e && e.data && e.data.name;

    const message = {
      text: `Dataset "${name}" created successfully .`,
      severity: 'info'
    };

    const { pageSize } = getPageData();

    yield put(fetchDatasets(1, pageSize));

    const loc = getLocation();
    history.push(loc.ListDataset());

    yield put(showAlertMessageAction(message));

    return e;
  },
  handleError: function*(e) {
    const message = {
      text: 'An error occurred while creating the dataset, try again please.',
      severity: 'error'
    };
    yield put(showAlertMessageAction(message));

    return e;
  }
};

const deleteDataset = {
  type: CONSTS.FETCH_CURRENT,
  apiMethod: api.deleteDataset,
  handleSuccess: function*() {
    const message = {
      text: 'Dataset deleted successfully.',
      severity: 'info'
    };

    const { page, pageSize } = getPageData();

    yield put(fetchDatasets(page, pageSize));

    yield put(showAlertMessageAction(message));
    return { data: null };
  }
};

const updateDataset = {
  type: CONSTS.FETCH_CURRENT,
  apiMethod: api.updateDataset,
  handleSuccess: function*(e) {
    const name = e && e.data && e.data.name;
    const message = {
      text: `Dataset "${name}" updated successfully .`,
      severity: 'info'
    };
    const { page, pageSize } = getPageData();

    yield put(fetchDatasets(page, pageSize));

    const loc = getLocation();
    history.push(loc.ListDataset());

    yield put(showAlertMessageAction(message));
    return { data: e.data };
  }
};

export default function*() {
  yield takeLatest(CONSTS.FETCH_LIST, getFetchSaga(listDatasets));
  yield takeLatest(CONSTS.FETCH_CREATE, getFetchSaga(createDataset));
  yield takeLatest(CONSTS.DELETE, getFetchSaga(deleteDataset));
  yield takeLatest(CONSTS.UPDATE, getFetchSaga(updateDataset));
}

import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import {useTranslation} from 'react-i18next';
import LanguageMenu from './LanguageMenu';
import {ReactComponent as RuFlagIcon} from '../icons/ru.svg';
import {ReactComponent as EnFlagIcon} from '../icons/en.svg';
import {useResponsive} from '../../utils/hooks';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {

  },
  rootMobile: {
    marginRight: `${theme.spacing(1)}px`
  },
  buttonText: {
    color: theme.palette.secondary.main,
    marginRight: `${theme.spacing(1)}px`,
    textTransform: 'capitalize',
    fontSize: '70%'
  },
  buttonTextMobile: {
    fontSize: `${theme.spacing(1.5)}px!important`
  },
  menuItem: {
    marginLeft: `${theme.spacing(1)}px`,
    color: theme.palette.secondary.contrastText,
    textTransform: 'capitalize',
  },
  icon: {
    width: 18
  },
  iconMobile: {
    width: '16px!important'
  }
}));

const LanguageSelector = () => {
  const classes = useStyles();
  const {i18n} = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const {isMobile} = useResponsive();

  const getLanguageNames = (lang) => {
    if (lang === 'en' || lang === 'en-EN') return 'En';
    if (lang === 'ru' || lang === 'ru-RU') return 'Ru';
    return ''
  }

  const getLanguageIcons = (lang) => {
    if (lang === 'en' || lang === 'en-EN') return <EnFlagIcon className={clsx(classes.icon, isMobile && classes.iconMobile)}/>;
    if (lang === 'ru' || lang === 'ru-RU') return <RuFlagIcon className={clsx(classes.icon, isMobile && classes.iconMobile)}/>;
    return ''
  }

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const position = 'top';
  return (
    <div className={clsx(classes.root,classes.rootMobile)}>
      <IconButton
        aria-controls={`language-menu-${position}`}
        aria-haspopup="true"
        onClick={handleOpen}
      >
        <div className={clsx(classes.buttonText, isMobile && classes.buttonTextMobile)}>
          {getLanguageNames(i18n.language)}
        </div>
        {getLanguageIcons(i18n.language)}
      </IconButton>
      <LanguageMenu
        anchorEl={anchorEl}
        getLanguageIcons={getLanguageIcons}
        position={position}
        setAnchorEl={setAnchorEl}
      />
    </div>
  );
};


export default LanguageSelector;

import * as CONSTS from './consts';
import * as api from '../api';
import { getFetchSaga } from '../utils/fetch';
import { put, takeLatest } from 'redux-saga/effects';
import { showAlertMessageAction } from '../alerts/actions';

const activateEmail = {
  type: CONSTS.EMAIL_ACTIVATION,
  apiMethod: api.activateEmail,
  handleSuccess: function*({ data }) {
    const message = {
      text: 'Activation successful.',
      severity: 'success'
    };
    yield put(showAlertMessageAction(message));
    return {'ok': true};
  },
  handleError: function*(e) {
    const error_text = e.response.data ? e.response.data.detail: 'Activation error'
    const message = {
      text: error_text,
      severity: 'error'
    };
    yield put(showAlertMessageAction(message));
    return {'error': error_text};
  }
};

export default function*() {
  yield takeLatest(CONSTS.ACTIVATE_EMAIL, getFetchSaga(activateEmail));
}

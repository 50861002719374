import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {availableLanguages} from './consts';
import PropTypes from 'prop-types';


const useStyles = makeStyles(theme => ({
  menuItem: {
    marginLeft: `${theme.spacing(1)}px`,
    color: theme.palette.secondary.contrastText,
    textTransform: 'capitalize',
  }
}));


const StyledMenu = withStyles( theme => ({
  paper: {
    border: `1px solid ${theme.palette.primary.contrastText}`,
    backgroundColor: theme.palette.primary.main,
  },
}))((props) => (
  <Menu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    elevation={0}
    getContentAnchorEl={null}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));


const LanguageMenu = ({anchorEl, setAnchorEl, position, getLanguageIcons}) => {
  const classes = useStyles();
  const {i18n} = useTranslation();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (index) => {
    i18n.changeLanguage(availableLanguages[index]);
    setAnchorEl(null);
  }

  return (
    <StyledMenu
      anchorEl={anchorEl}
      id={`language-menu-${position}`}
      keepMounted
      onClose={handleClose}
      open={Boolean(anchorEl)}
    >
      {availableLanguages.map((item, index) => <MenuItem
        key={item}
        onClick={() => handleClick(index)}
        value={item}
      >
        {getLanguageIcons(item)}
        <span className={classes.menuItem}>{item}</span>
      </MenuItem>)}
    </StyledMenu>
  );
};

LanguageMenu.propTypes = {
  anchorEl: PropTypes.object,
  getLanguageIcons: PropTypes.func,
  position: PropTypes.string,
  setAnchorEl: PropTypes.func
};

export default LanguageMenu;

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';

import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {},
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },

  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end'
  },
  tableCell: {
    padding: '7px'
  },
  name: {
    fontSize: '14px',
    fontWeight: 'bold'
  },
  rightAlignBold: {
    textAlign: 'right',
    fontWeight: 'bold'
  },
  backendCell: {
    textAlign: 'center',
    fontWeight: 'bold'
  },
  fixed: {
    maxHeight: '700px',
    overflowY: 'auto'
  }
}));

const TasksList = props => {
  const {
    className,
    tasks,
    selected,
    setSelected,
    backendsData,
    isFiltered
  } = props;

  const itemsById = backendsData && backendsData.itemsById;

  const classes = useStyles();

  const handleSelectAll = event => {
    const selectedTasks = event.target.checked
      ? tasks.map(customer => customer.id)
      : [];

    setSelected(selectedTasks);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Card>
        <CardHeader
          subheader={
            !isFiltered &&
            'For a detailed search, try to select a dataset or template'
          }
          title={`Found ${tasks && tasks.length} tasks`}
        />
        <Divider />
        <CardContent className={classes.content}>
          <div className={clsx(classes.inner, classes.fixed)}>
            <Table stickyHeader className={clsx(classes.table)}>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selected.length === tasks.length}
                      color="primary"
                      indeterminate={
                        selected.length > 0 && selected.length < tasks.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Backend</TableCell>
                  <TableCell>Tensor</TableCell>
                  <TableCell>Params</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tasks.map(task => (
                  <TableRow
                    hover
                    key={task.id}
                    selected={selected.indexOf(task.id) !== -1}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selected.indexOf(task.id) !== -1}
                        color="primary"
                        onChange={event => handleSelectOne(event, task.id)}
                        value={selected.indexOf(task.id) !== -1}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <div className={classes.nameCell}>
                        <div>
                          <div color="inherit" className={classes.name}>
                            <span>Task ID: {task.id}</span>
                            <br />
                          </div>
                          <div>
                            {moment(task.created).format('MMMM Do HH:mm:ss')}
                          </div>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell className={classes.backendCell}>
                      {itemsById &&
                        itemsById[task.backend] &&
                        itemsById[task.backend].name}
                    </TableCell>
                    <TableCell>{task.tensor}</TableCell>
                    <TableCell>
                      <div className={classes.bold}>
                        {(() => {
                          const params = JSON.parse(
                            JSON.stringify(task.params, null, 2)
                          );
                          const results = [];
                          Object.keys(params).forEach(k => {
                            let r = Math.random()
                              .toString(36)
                              .substring(3);
                            results.push(
                              <tr key={r}>
                                <td>{k}</td>
                                <td className={classes.rightAlignBold}>
                                  {typeof params[k] === 'object' ? (
                                    JSON.stringify(params[k], null, 2)
                                  ) : typeof params[k] === 'boolean' ? (
                                    params[k] ? (
                                      <div>True</div>
                                    ) : (
                                      <div>False</div>
                                    )
                                  ) : (
                                    params[k]
                                  )}
                                </td>
                              </tr>
                            );
                          });
                          return (
                            <table>
                              <tbody>{[...results]}</tbody>
                            </table>
                          );
                        })()}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

TasksList.propTypes = {
  backendsData: PropTypes.object,

  className: PropTypes.string,

  selected: PropTypes.array,
  setSelected: PropTypes.func,
  tasks: PropTypes.array.isRequired,

  // eslint-disable-next-line react/sort-prop-types
  isFiltered: PropTypes.bool
};

TasksList.defaultProps = {
  tasks: []
};

export default TasksList;

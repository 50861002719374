import { getFetchSaga, successSaga } from '../utils/fetch';
import { takeLatest, put } from 'redux-saga/effects';
import * as CONSTS from './consts';
import * as api from '../api';
import { fetchMatrix, updateList } from './actions';
import { history } from '../../commons';
import { getModal } from '../../utils/queryParams';
import { showAlertMessageAction } from '../alerts/actions';
import { select, takeEvery } from '@redux-saga/core/effects';
import { findIndex } from '../../utils/tools';
import { matrixListSelector } from './selectors';

const uploadMatrix = {
  type: CONSTS.CURRENT,
  apiMethod: api.uploadMatrix,
  handleSuccess: function*(response) {
    const modal = getModal(history);
    modal[1]();
    yield put(fetchMatrix(1));
    return response.data;
  }
};

const listMatrix = {
  type: CONSTS.LIST,
  apiMethod: api.listMatrix
};

const deleteFromState = function*(matrixId) {
  const state = yield select(matrixListSelector);

  let items = state.items || [];
  const itemIndex = findIndex(items, { id: matrixId });
  if (itemIndex !== -1) {
    items.splice(itemIndex, 1);
    yield successSaga(updateList([...items]));
  }
};

const deleteMatrix = {
  type: CONSTS.FETCH_CURRENT,
  apiMethod: api.deleteMatrix,
  handleSuccess: function*(r) {
    yield deleteFromState(r.data);
    return { data: null };
  }
};

const bulkDeleteMatrix = {
  type: CONSTS.FETCH_LIST,
  apiMethod: api.bulkDeleteMatrix,
  handleSuccess: function*(r) {
    const tensorIds = r.data;
    for (let i = 0; i < tensorIds.length; i++) {
      yield deleteFromState(tensorIds[i]);
    }
    const message = {
      text: `Matrices with ID ${tensorIds
        .map(id => id)
        .join(' ')} deleted successfully`,
      severity: 'info'
    };
    yield put(showAlertMessageAction(message));
  }
};

const updateFromState = function*(item) {
  const state = yield select(matrixListSelector);
  let items = state.items || [];
  const itemIndex = findIndex(items, { id: item.id });
  if (itemIndex !== -1) {
    const result = items.slice();
    result[itemIndex] = { ...item };
    yield successSaga(updateList([...result]));
  }
};

const updateMatrix = {
  type: CONSTS.FETCH_CURRENT,
  apiMethod: api.updateMatrix,
  handleSuccess: function*(e) {

    const item = e && e.data;
    const name = item && item.name;

    const message = {
      text: `Matrix "${name}" edited successfully .`,
      severity: 'info'
    };

    if (item) {
      yield updateFromState(item);
    }

    yield put(showAlertMessageAction(message));
    return { data: e.data };
  }
};

export default function*() {
  yield takeLatest(CONSTS.FETCH, getFetchSaga(listMatrix));
  yield takeLatest(CONSTS.UPLOAD, getFetchSaga(uploadMatrix));
  yield takeEvery(CONSTS.DELETE, getFetchSaga(deleteMatrix));
  yield takeLatest(CONSTS.UPDATE, getFetchSaga(updateMatrix));
  yield takeEvery(CONSTS.BULK_DELETE, getFetchSaga(bulkDeleteMatrix));
}

import { getFetchSaga } from '../utils/fetch';
import { takeLatest } from 'redux-saga/effects';
import * as CONSTS from './consts';
import * as api from '../api';

const listSaga = {
  type: CONSTS.LIST,
  apiMethod: api.listValidators
};

export default function*() {
  yield takeLatest(CONSTS.FETCH_LIST, getFetchSaga(listSaga));
}

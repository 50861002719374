import React from 'react';
import { matchPath, Redirect, Route, Switch } from 'react-router-dom';
import { get } from './utils/tools';
import { RouteWithLayout } from './components';
import { Main } from './layouts';
import SignUp from './views/SignUp/SignUp';
import Login from './views/Login';
import { history } from './commons';
import Dashboard from './views/Dashboard/Dashboard';
import EmailActivation from './views/EmailActivation';

const TaskManager = React.lazy(() => import('./views/TaskManager/TaskManager'));
const Task = React.lazy(() => import('./views/Task'));
const Circuits = React.lazy(() => import('./views/Circuits'));
const NotFound = React.lazy(() => import('./views/NotFound'));
const NewTask = React.lazy(() => import('./views/NewTask/NewTask'));
const UserProfile = React.lazy(() => import('./views/UserProfile/UserProfile'));
const NewAdiabaticTask = React.lazy(() => import('./views/NewTask/components/NewAdiabaticTask'));
const Landing = React.lazy(() => import('./views/Landing/Landing'));
const Services = React.lazy(() => import('./views/Services'));
const Presets = React.lazy(() => import('./views/Presets'));
const Management = React.lazy(() => import('./views/Management/Management'));
const ServerUtilization = React.lazy(() => import('./views/ServerUtilization/ServerUtilization'));
const Help = React.lazy(() => import('./views/Help/Help'));
const Logs = React.lazy(() => import('./views/Logs/Logs'));
const TermsOfUse = React.lazy(() => import('./views/TermsOfUse'));
const PrivacyPolicy = React.lazy(() => import('./views/PrivacyPolicy'));


const ROUTES = {
  TASKS: '/tasks',
  CIRCUITS: '/circuits',
  NEW_TASK: '/new-task',
  DASHBOARD: '/dashboard',
  DATASETS: '/datasets',
  CHAINS: '/chains',
  NOTEBOOKS: '/notebooks',
  SERVICES: '/services',
  BENCHMARKS: '/benchmarks',
  PRESETS: '/presets',
  MAIN: '/',
  PROFILE: '/profile',
  HELP: '/help',
  MANAGEMENT: '/management',
  SERVERS: '/servers',
  TERMS_OF_USE: '/terms-of-use',
  LOGS: '/logs',

  NOT_FOUND: '/not-found',
  LOGIN: '/login',
  SIGN_UP: '/sign-up',
  PRIVACY_POLICY: '/privacy-policy',

  EMAIL_ACTIVATION: '/activate/:uid/:token'
};

ROUTES.TASK = `${ROUTES.TASKS}/:id`;
ROUTES.TASKS_TAB = `${ROUTES.TASKS}/tabs/:tab`;
ROUTES.NEW_TASKS_TAB = `${ROUTES.TASKS}/tabs/new`;
ROUTES.PROFILE_TAB = `${ROUTES.PROFILE}/:tab`;
ROUTES.IN_PROGRESS_TASKS_TAB = `${ROUTES.TASKS}/tabs/in-progress`;
ROUTES.COMPLETED_TASKS_TAB = `${ROUTES.TASKS}/tabs/completed`;
ROUTES.NEW_ADIABATIC_TASK = `${ROUTES.NEW_TASK}/adiabatic/`

ROUTES.DATASET = `${ROUTES.DATASETS}/:id`;
ROUTES.CIRCUITS_ALL = `${ROUTES.CIRCUITS}/:id?`
ROUTES.PROFILE_SETTINGS = `${ROUTES.MANAGEMENT}/:id?`

const getTab = (loc, route) => {
  const match = matchPath(loc, {
    path: route,
    exact: true,
    strict: true
  });
  return match
    ? { tab: get(match, 'params.tab', undefined) }
    : { tab: undefined };
};


const getId = (loc, route) => {
  const match = matchPath(loc, {
    path: route,
    exact: true,
    strict: true
  });
  return get(match, 'params.id', undefined);
};

const getActivationData = (loc, route) => {
  const match = matchPath(loc, {
    path: route,
    exact: true,
    strict: true
  });
  return match? match.params: null;
};

export const getCircuitId = loc => getId(loc, ROUTES.CIRCUITS_ALL)
export const toTask = id => history.push(`${ROUTES.TASKS}/${id}`)
export const getCircuitPath = id => `${ROUTES.CIRCUITS}/${id}`
export const toPresets = id => `${ROUTES.PRESETS}${id ? `/${id}` : ''}`
export const toProfile = id => `${ROUTES.MANAGEMENT}/${id}`
export const toTermOfUse = () => ROUTES.TERMS_OF_USE
export const toPrivacyPolicy = () => ROUTES.PRIVACY_POLICY

const getLocation = () => {
  const loc = window.location.pathname;
  return {
    current: () => loc,
    main: () => ROUTES.MAIN,

    Dashboard: () => ROUTES.DASHBOARD,
    isDashboard: () => loc === ROUTES.DASHBOARD,

    Tasks: () => ROUTES.TASKS,
    Circuits: () => ROUTES.CIRCUITS,
    isCircuits: () => loc.slice(0, ROUTES.CIRCUITS.length) === ROUTES.CIRCUITS,
    isTasks: () => loc === ROUTES.TASKS || loc.search(/^\/tasks\/tabs/) !== -1,
    parseTasksTab: () => getTab(loc, ROUTES.TASKS_TAB),
    NewTasks: () => ROUTES.NEW_TASKS_TAB,
    InProgressTasks: () => ROUTES.IN_PROGRESS_TASKS_TAB,
    CompletedTasks: () => ROUTES.COMPLETED_TASKS_TAB,
    toTaskTab: tab => `${ROUTES.TASKS}/tabs/${tab}`,

    isNewTasks: () => loc.search(/^\/tasks\/tabs\/new/) !== -1,
    isInProgressTasks: () => loc.search(/^\/tasks\/tabs\/in-progress/) !== -1,
    isCompletedTasks: () => loc.search(/^\/tasks\/tabs\/completed/) !== -1,

    isTasksList: () => loc === ROUTES.TASKS || loc.search(/^\/tasks\/tabs/) !== -1,
    isTask: () => loc.search(/^\/tasks\/\d+/) !== -1,
    isServer: () => loc.search(/^\/servers\/\d+/) !== -1,
    parseTaskId: () => getId(loc, ROUTES.TASK),
    parseServerId: () => getId(loc, ROUTES.SERVER),

    NewTask: () => ROUTES.NEW_TASK,
    NewAdiabaticTask: () => ROUTES.NEW_ADIABATIC_TASK,

    Chains: () => ROUTES.CHAINS,
    isChains: () => loc === ROUTES.CHAINS,

    Notebooks: () => ROUTES.NOTEBOOKS,
    isNotebooks: () => loc === ROUTES.NOTEBOOKS,

    Presets: () => ROUTES.PRESETS,

    Services: () => ROUTES.SERVICES,
    isServices: () => loc === ROUTES.SERVICES,

    Benchmarks: () => ROUTES.BENCHMARKS,
    isBenchmarks: () => loc === ROUTES.BENCHMARKS,

    Datasets: () => ROUTES.DATASETS,
    isDatasets: () => loc === ROUTES.DATASETS,

    Profile: () => ROUTES.PROFILE,
    isProfile: () => loc === ROUTES.PROFILE,
    parseProfileTab: () => getTab(loc, ROUTES.PROFILE_TAB),
    parseProfileId: () => getId(loc, ROUTES.PROFILE_SETTINGS),

    ServersList: () => ROUTES.SERVERS,
    isServersList: () => loc === ROUTES.SERVERS,

    Help: () => ROUTES.HELP,
    isHelp: () => loc === ROUTES.HELP,

    Logs: () => ROUTES.LOGS,
    isLogs: () => loc === ROUTES.LOGS,

    Management: () => ROUTES.MANAGEMENT,
    isManagement: () => loc === ROUTES.MANAGEMENT,

    TermsOfUse: () => ROUTES.TERMS_OF_USE,
    isTermsOfUse: () => loc === ROUTES.TERMS_OF_USE,

    PrivacyPolicy: () => ROUTES.PRIVACY_POLICY,
    isPrivacyPolicy: () => loc === ROUTES.PRIVACY_POLICY,

    SignIn: () => ROUTES.LOGIN,
    SignUp: () => ROUTES.SIGN_UP,

    EmailActivation: () => ROUTES.EMAIL_ACTIVATION,
    getActivationData: () => getActivationData(loc, ROUTES.EMAIL_ACTIVATION),

    NotFound: () => ROUTES.NOT_FOUND,
  };
};

const Routes = () => {
  return (
    <Switch>
      <RouteWithLayout
        component={Task}
        exact
        layout={Main}
        path={ROUTES.TASK}
      />
      <RouteWithLayout
        component={TaskManager}
        exact
        layout={Main}
        path={ROUTES.TASKS}
      />
      <RouteWithLayout
        component={Circuits}
        exact
        layout={Main}
        path={ROUTES.CIRCUITS_ALL}
      />
      <RouteWithLayout
        component={TaskManager}
        exact
        layout={Main}
        path={ROUTES.TASKS_TAB}
      />
      <RouteWithLayout
        component={NewTask}
        exact
        layout={Main}
        path={ROUTES.NEW_TASK}
      />
      <RouteWithLayout
        component={NewAdiabaticTask}
        exact
        layout={Main}
        path={ROUTES.NEW_ADIABATIC_TASK}
      />
      <RouteWithLayout
        component={UserProfile}
        exact
        layout={Main}
        path={ROUTES.PROFILE}
      />
      <RouteWithLayout
        component={Services}
        exact
        layout={Main}
        path={ROUTES.SERVICES}
      />
      <RouteWithLayout
        component={ServerUtilization}
        exact
        layout={Main}
        path={ROUTES.SERVERS}
      />

      <RouteWithLayout
        component={Presets}
        exact
        layout={Main}
        path={ROUTES.PRESETS}
      />

      <Route
        component={Login}
        exact
        path={ROUTES.LOGIN}
      />
      <Route
        component={SignUp}
        exact
        path={ROUTES.SIGN_UP}
      />

      <Route
        component={Landing}
        exact
        path={ROUTES.MAIN}
      />

      <RouteWithLayout
        component={Management}
        exact
        layout={Main}
        path={ROUTES.MANAGEMENT}
      />

      <RouteWithLayout
        component={Management}
        exact
        layout={Main}
        path={ROUTES.PROFILE_SETTINGS}
      />

      <RouteWithLayout
        component={Dashboard}
        exact
        layout={Main}
        path={ROUTES.DASHBOARD}
      />
      <RouteWithLayout
        component={Help}
        exact
        layout={Main}
        path={ROUTES.HELP}
      />
      <RouteWithLayout
        component={Logs}
        exact
        layout={Main}
        path={ROUTES.LOGS}
      />

      <Route
        component={TermsOfUse}
        exact
        path={ROUTES.TERMS_OF_USE}
      />

      <Route
        component={PrivacyPolicy}
        exact
        path={ROUTES.PRIVACY_POLICY}
      />
      <Route
        component={EmailActivation}
        exact
        path={ROUTES.EMAIL_ACTIVATION}
      />

      <Route
        component={NotFound}
        exact
        path={ROUTES.NOT_FOUND}
      />
      <Redirect to={ROUTES.NOT_FOUND} />
    </Switch>
  );
};

export default Routes;
export { getLocation };

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton } from '@material-ui/core';
import { useAction } from '../../utils/hooks';
import { fetchLogoutAction } from '../../core/users/actions';
import { css } from '../../const';
import { ReactComponent as InputIcon } from '../../components/icons/exit.svg';
import {getLocation} from '../../Routes';
import NewTaskTitle from './SectionTitles/NewTaskTitle';
import TasksTitle from './SectionTitles/TasksTitle';
import CircuitsTitle from './SectionTitles/CircuitsTitle';
import ServicesTitle from './SectionTitles/ServicesTitle';
import PresetsTitle from './SectionTitles/PresetsTitle';
import ServersListTitle from './SectionTitles/ServersListTitle';
import {useHistory} from 'react-router';
import Button from '@material-ui/core/Button';
import ManagementTitle from './SectionTitles/ManagementTitle';
import ProfileTitle from './SectionTitles/ProfileTitle';
import DashboardTitle from './SectionTitles/DashboardTitle';
import HelpTitle from './SectionTitles/HelpTitle';

const useStyles = makeStyles(theme => ({
  appBar: {
    boxShadow: 'none',
    color: theme.palette.text.primary,
    width: `calc(100% - ${css.drawerClosedWidth}px - ${css.drawerBorderWidth}px)`,
    backgroundColor: theme.palette.secondary.contrastText,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    '& > .MuiToolbar-regular': {
      height: css.topBarHeight
    }
  },
  appBarShift: {
    marginLeft: css.drawerWidth,
    width: `calc(100% - ${css.drawerWidth}px - ${css.drawerBorderWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  backButton: {
    '&.MuiButton-root': {
      minWidth: 0
    }
  },
  toolbar: {
    padding: theme.spacing(0, 4)
  },
  bottomBorder: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    borderBottom: `2px dashed ${theme.palette.secondary.main}`
  },
  icon: {
    width: 22,
    stroke: theme.palette.secondary.main,
    fill: theme.palette.secondary.main,
    strokeWidth: '0.2px'
  }
}));

const getBasePath = path => path.split('/').slice(0, 2).join('/')


const Topbar = props => {
  const { open, path, ...rest } = props;
  const basePath = getBasePath(path)
  const logout = useAction(fetchLogoutAction);
  const history = useHistory()
  const loc = getLocation();

  const buttons = {
    [loc.NewTask()]: <NewTaskTitle />,
    [loc.NewAdiabaticTask()]: <NewTaskTitle />,
    [loc.Tasks()]: <TasksTitle />,
    [loc.NewTasks()]: <TasksTitle />,
    [loc.InProgressTasks()]: <TasksTitle />,
    [loc.CompletedTasks()]: <TasksTitle />,
    [loc.Circuits()]: <CircuitsTitle />,
    [loc.Services()]: <ServicesTitle />,
    [loc.Presets()]: <PresetsTitle />,
    [loc.Management()]: <ManagementTitle />,
    [loc.Profile()]: <ProfileTitle />,
    [loc.Dashboard()]: <DashboardTitle />,
    [loc.ServersList()]: <ServersListTitle />,
    [loc.Help()]: <HelpTitle />,
  };

  const classes = useStyles();
  return (
    <AppBar
      {...rest}
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open
      })}
    >
      <Toolbar className={classes.toolbar}>
        <div className={classes.bottomBorder}>
          <Button
            className={classes.backButton}
            color="secondary"
            onClick={() => history.goBack()}
          >
            {'«'}
          </Button>
          <React.Fragment>
            {buttons[basePath]}
          </React.Fragment>
          <div className={classes.flexGrow} />
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={() => logout()}
          >
            <InputIcon className={classes.icon} />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired
};

export default Topbar;

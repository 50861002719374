import React from 'react';

const LogoTitle = () => {
  return (
    <>
      <path
        d="M159.283 8.83313H155.972V59.0151H159.283V8.83313Z"
        fill="#86D0F4"
      />
      <path
        d="M72.684 34.4468H77.737C82.6158 34.4468 85.0552 31.4847 85.0552 25.9089C85.0552 20.6817 82.6158 17.8938 77.737 17.8938H72.5097V34.4468H72.684ZM76.8658 37.5832H72.8582V52.0454H69.5476V14.9316H78.0855C84.881 14.9316 88.7143 18.9392 88.7143 25.7347C88.7143 32.1817 85.578 36.3635 80.5249 37.2347L89.5855 51.8711H85.7522L76.8658 37.5832Z"
        fill="white"
      />
      <path
        d="M107.533 48.7348L103.699 44.2045L105.79 42.2879L109.624 46.8182C110.669 45.25 111.366 43.1591 111.366 40.3712V26.2575C111.366 20.5075 108.927 17.1969 104.048 17.1969C99.169 17.1969 96.7296 20.5075 96.7296 26.2575V40.3712C96.7296 46.1212 99.169 49.4318 104.048 49.4318C105.442 49.6061 106.661 49.2576 107.533 48.7348ZM112.76 55.1818L109.624 51.3485C108.055 52.2197 106.313 52.7424 104.048 52.7424C97.4265 52.7424 93.4189 48.0379 93.4189 40.5454V26.4317C93.4189 18.9393 97.4265 14.2347 104.048 14.2347C110.669 14.2347 114.677 18.9393 114.677 26.4317V40.5454C114.677 44.3788 113.631 47.5151 111.714 49.6061L114.677 53.2652L112.76 55.1818Z"
        fill="white"
      />
      <path
        d="M120.427 41.5907V25.5604C120.427 18.4164 124.434 14.4088 130.358 14.4088C136.457 14.4088 140.29 18.2422 140.639 25.0376H137.502C137.154 19.8103 134.54 17.3709 130.358 17.3709C126.351 17.3709 123.563 19.9846 123.563 25.5604V41.5907C123.563 47.1665 126.351 49.7801 130.533 49.7801C134.715 49.7801 137.154 47.1665 137.677 42.1134H140.813C140.29 48.9089 136.457 52.7423 130.358 52.7423C124.608 52.7423 120.427 48.7347 120.427 41.5907Z"
        fill="white"
      />
      <path
        d="M174.442 19.2878H175.313C176.184 19.2878 176.533 18.7651 176.533 17.7196C176.533 16.6741 176.01 16.1514 175.313 16.1514H174.442V19.2878ZM175.313 20.5075H174.616V23.4696H173.222V15.106H175.487C177.056 15.106 178.101 16.1514 178.101 17.7196C178.101 19.1135 177.578 19.9848 176.533 20.3332L178.449 23.6439H177.056L175.313 20.5075Z"
        fill="white"
      />
      <path
        d="M183.677 17.1968V23.4695H182.806L182.631 22.7726C182.283 23.2953 181.76 23.6438 181.063 23.6438C180.018 23.6438 179.321 22.7726 179.321 21.2044V17.1968H180.54V21.2044C180.54 22.2498 180.889 22.5983 181.412 22.5983C182.109 22.5983 182.457 22.0756 182.457 21.2044V17.1968H183.677Z"
        fill="white"
      />
      <path
        d="M185.071 21.5529H186.29C186.29 22.0756 186.639 22.5984 187.336 22.5984C188.033 22.5984 188.381 22.2499 188.381 21.7271C188.381 20.5074 185.245 20.8559 185.245 18.765C185.245 17.7196 186.116 17.0226 187.336 17.0226C188.73 17.0226 189.427 17.8938 189.427 19.1135H188.207C188.207 18.5908 187.859 18.068 187.162 18.068C186.639 18.068 186.29 18.4165 186.29 18.765C186.29 19.9847 189.427 19.462 189.427 21.7271C189.427 22.7726 188.556 23.6438 187.336 23.6438C185.768 23.6438 185.071 22.7726 185.071 21.5529Z"
        fill="white"
      />
      <path
        d="M190.298 21.5529H191.518C191.518 22.0756 191.866 22.5984 192.563 22.5984C193.26 22.5984 193.609 22.2499 193.609 21.7271C193.609 20.5074 190.472 20.8559 190.472 18.765C190.472 17.7196 191.343 17.0226 192.563 17.0226C193.957 17.0226 194.654 17.8938 194.654 19.1135H193.434C193.434 18.5908 193.086 18.068 192.389 18.068C191.866 18.068 191.518 18.4165 191.518 18.765C191.518 19.9847 194.654 19.462 194.654 21.7271C194.654 22.7726 193.783 23.6438 192.563 23.6438C191.169 23.6438 190.298 22.7726 190.298 21.5529Z"
        fill="white"
      />
      <path
        d="M196.048 17.1968H197.268V23.4696H196.048V17.1968ZM195.874 15.4544C195.874 15.1059 196.222 14.7574 196.571 14.7574C196.919 14.7574 197.268 15.1059 197.268 15.4544C197.268 15.8029 196.919 16.1514 196.571 16.1514C196.222 16.1514 195.874 15.8029 195.874 15.4544Z"
        fill="white"
      />
      <path
        d="M201.798 20.6817H200.927C200.23 20.6817 199.881 21.0302 199.881 21.7271C199.881 22.2499 200.23 22.5984 200.927 22.5984C201.624 22.5984 201.972 22.2499 201.972 21.5529V20.6817H201.798ZM203.018 19.2877V23.4696H202.147V22.9468C201.798 23.4696 201.45 23.6438 200.753 23.6438C199.533 23.6438 198.836 22.9468 198.836 21.7271C198.836 20.5074 199.707 19.8105 201.101 19.8105H201.972V19.2877C201.972 18.5908 201.624 18.068 201.101 18.068C200.578 18.068 200.23 18.4165 200.056 18.9393H198.836C198.836 17.7196 199.707 17.0226 200.927 17.0226C202.321 17.0226 203.018 17.8938 203.018 19.2877Z"
        fill="white"
      />
      <path
        d="M208.942 19.462V23.4696H207.722V19.462C207.722 18.4165 207.374 18.068 206.851 18.068C206.154 18.068 205.806 18.5908 205.806 19.462V23.4696H204.586V17.1968H205.457L205.631 17.8938C205.98 17.3711 206.503 17.0226 207.2 17.0226C208.245 17.0226 208.942 17.7196 208.942 19.462Z"
        fill="white"
      />
      <path
        d="M176.707 36.0151C176.881 35.6666 176.881 35.3181 176.881 34.9696V31.8332C176.881 30.7878 176.359 30.2651 175.487 30.2651C174.616 30.2651 174.093 30.7878 174.093 31.8332V34.9696C174.093 36.0151 174.616 36.5378 175.487 36.5378H175.662L175.139 35.8408L175.836 35.1439L176.707 36.0151ZM178.275 34.9696C178.275 35.8408 178.101 36.5378 177.578 37.0605L178.275 37.9318L177.578 38.6287L176.881 37.7575C176.533 37.9318 176.184 37.9318 175.836 37.9318C174.268 37.9318 173.222 36.8863 173.222 35.1439V32.0075C173.222 30.2651 174.268 29.2196 175.836 29.2196C177.404 29.2196 178.449 30.2651 178.449 32.0075V34.9696H178.275Z"
        fill="white"
      />
      <path
        d="M184.025 31.3105V37.5833H183.154L182.98 36.8863C182.631 37.4091 182.109 37.7575 181.412 37.7575C180.366 37.7575 179.669 36.8863 179.669 35.3181V31.3105H180.889V35.3181C180.889 36.3636 181.237 36.7121 181.76 36.7121C182.457 36.7121 182.806 36.1893 182.806 35.3181V31.3105H184.025Z"
        fill="white"
      />
      <path
        d="M188.73 34.7953H187.859C187.162 34.7953 186.813 35.1438 186.813 35.8408C186.813 36.3635 187.162 36.712 187.859 36.712C188.556 36.712 188.904 36.3635 188.904 35.6665V34.7953H188.73ZM189.775 33.4014V37.5832H188.904V37.0605C188.556 37.5832 188.207 37.7575 187.51 37.7575C186.29 37.7575 185.593 37.0605 185.593 35.8408C185.593 34.6211 186.465 33.9241 187.859 33.9241H188.73V33.4014C188.73 32.7044 188.381 32.1817 187.859 32.1817C187.336 32.1817 186.987 32.5302 186.813 33.0529H185.593C185.593 31.8332 186.465 31.1362 187.684 31.1362C189.078 31.1362 189.775 32.0074 189.775 33.4014Z"
        fill="white"
      />
      <path
        d="M195.874 33.5756V37.5832H194.654V33.5756C194.654 32.5302 194.306 32.1817 193.783 32.1817C193.086 32.1817 192.737 32.7044 192.737 33.5756V37.5832H191.518V31.3105H192.389L192.563 32.0074C192.912 31.4847 193.434 31.1362 194.131 31.1362C195.177 31.1362 195.874 31.8332 195.874 33.5756Z"
        fill="white"
      />
      <path
        d="M200.23 36.5377V37.5832H199.707C198.487 37.5832 197.616 36.712 197.616 35.4923V32.1816H196.745V31.1362H197.616V29.568H198.836V31.1362H200.056V32.1816H198.836V35.4923C198.836 36.1892 199.184 36.5377 199.707 36.5377H200.23Z"
        fill="white"
      />
      <path
        d="M205.806 31.3105V37.5833H204.934L204.76 36.8863C204.412 37.4091 203.889 37.7575 203.192 37.7575C202.146 37.7575 201.449 36.8863 201.449 35.3181V31.3105H202.669V35.3181C202.669 36.3636 203.018 36.7121 203.54 36.7121C204.237 36.7121 204.586 36.1893 204.586 35.3181V31.3105H205.806Z"
        fill="white"
      />
      <path
        d="M214.866 33.5756V37.5832H213.647V33.5756C213.647 32.5302 213.298 32.1817 212.775 32.1817C212.078 32.1817 211.73 32.7044 211.73 33.5756V37.5832H210.51V33.5756C210.51 32.5302 210.162 32.1817 209.639 32.1817C208.942 32.1817 208.593 32.7044 208.593 33.5756V37.5832H207.374V31.3105H208.245L208.419 32.0074C208.768 31.4847 209.29 31.1362 209.987 31.1362C210.684 31.1362 211.207 31.4847 211.556 32.1817C211.904 31.4847 212.427 31.1362 213.124 31.1362C214.169 31.1362 214.866 32.0074 214.866 33.5756Z"
        fill="white"
      />
      <path
        d="M173.048 49.2574V45.7726C173.048 44.0302 173.919 43.1589 175.487 43.1589C177.056 43.1589 177.927 44.0302 177.927 45.7726H176.707C176.707 44.7271 176.184 44.3786 175.313 44.3786C174.616 44.3786 174.093 44.9014 174.093 45.9468V49.4317C174.093 50.4771 174.616 50.8256 175.313 50.8256C176.184 50.8256 176.533 50.3029 176.707 49.4317H177.927C177.927 51.1741 176.881 52.0453 175.313 52.0453C173.919 51.8711 173.048 50.8256 173.048 49.2574Z"
        fill="white"
      />
      <path
        d="M180.366 47.5151V48.0378H182.457V47.5151C182.457 46.6439 182.109 46.2954 181.412 46.2954C180.715 46.2954 180.366 46.6439 180.366 47.5151ZM183.677 49.7802C183.503 51.1742 182.806 51.8712 181.412 51.8712C180.018 51.8712 179.321 50.9999 179.321 49.4318V47.5151C179.321 45.9469 180.192 45.0757 181.586 45.0757C182.98 45.0757 183.851 45.9469 183.851 47.5151V48.909H180.54V49.4318C180.54 50.303 180.889 50.6515 181.586 50.6515C182.283 50.6515 182.631 50.303 182.631 49.606H183.677V49.7802Z"
        fill="white"
      />
      <path
        d="M189.427 47.6894V51.697H188.207V47.6894C188.207 46.6439 187.859 46.2955 187.336 46.2955C186.639 46.2955 186.29 46.8182 186.29 47.6894V51.697H185.071V45.4242H185.942L186.116 46.1212C186.465 45.5985 186.987 45.25 187.684 45.25C188.73 45.25 189.427 45.947 189.427 47.6894Z"
        fill="white"
      />
      <path
        d="M193.957 50.6515V51.6969H193.434C192.215 51.6969 191.344 50.8257 191.344 49.606V46.2954H190.472V45.2499H191.344V43.6818H192.563V45.2499H193.783V46.2954H192.563V49.606C192.563 50.303 192.912 50.6515 193.434 50.6515H193.957Z"
        fill="white"
      />
      <path
        d="M196.222 47.5151V48.0378H198.313V47.5151C198.313 46.6439 197.965 46.2954 197.268 46.2954C196.571 46.2954 196.222 46.6439 196.222 47.5151ZM199.533 49.7802C199.359 51.1742 198.662 51.8712 197.268 51.8712C195.874 51.8712 195.177 50.9999 195.177 49.4318V47.5151C195.177 45.9469 196.048 45.0757 197.442 45.0757C198.836 45.0757 199.707 45.9469 199.707 47.5151V48.909H196.396V49.4318C196.396 50.303 196.745 50.6515 197.442 50.6515C198.139 50.6515 198.487 50.303 198.487 49.606H199.533V49.7802Z"
        fill="white"
      />
      <path
        d="M204.237 45.25V46.4697C204.063 46.2955 203.715 46.2955 203.366 46.2955C202.495 46.2955 202.147 46.8182 202.147 47.8636V51.697H200.927V45.4242H201.798L201.972 46.1212C202.321 45.5985 202.844 45.25 203.541 45.25C203.889 45.25 204.063 45.25 204.237 45.25Z"
        fill="white"
      />
    </>
  );
};

const Logo = ({ height, title }) => {
  return (
    <svg
      height={height}
      viewBox="0 0 215 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      {title ? <LogoTitle /> : null}
      <path
        d="M0.72168 38.2803L37.6612 59.7122V74.0001L5.42624 55.3561C2.46411 53.6137 0.72168 50.4773 0.72168 46.9924V38.2803Z"
        fill="#86D0F4"
      />
      <path
        d="M15.0096 21.0302L37.6612 7.96195L25.29 0.817993L15.0096 6.74225V21.0302Z"
        fill="#65B7E3"
      />
      <path
        d="M35.5703 46.6438L27.0323 51.5226L35.5703 56.4014V46.6438Z"
        fill="#5AA8D5"
      />
      <path
        d="M13.0929 43.3332V7.96191L0.72168 15.1059V36.1893L13.0929 43.3332Z"
        fill="#73C3ED"
      />
      <path
        d="M37.6611 24.1665V59.5378L45.1536 55.1817C48.1157 53.4393 49.8581 50.3029 49.8581 46.9923V31.3105L37.6611 24.1665Z"
        fill="#4D9AC8"
      />
      <path
        d="M27.0323 15.9773L50.0324 29.2197V15.1061L39.4036 9.00757L27.0323 15.9773Z"
        fill="#5AA8D5"
      />
    </svg>
  );
};

export default Logo;

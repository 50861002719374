import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(4)
  },
  stepTitle: {
    '& span': {
      color: theme.palette.info.main
    }
  },
  content: {
    padding: 0,
    margin: `0 ${theme.spacing(2)}px`,
    flexGrow: 1,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  stepContent: {
    display: 'flex',
    flexDirection: 'column',
    '& > .MuiCard-root': {
      display: 'flex',
      flexDirection: 'row',
      width: '100%'
    }
  },
  inner: {
    minWidth: 800
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const StepWrapper = ({
  title,
  children,
  step,
  action,
  footer,
  contentRef,
  titleComponents
}) => {
  const classes = useStyles();
  const titleComponent = titleComponents ? (
    titleComponents
  ) : (
    <Typography className={classes.stepTitle} variant="h6">
      <span>STEP {step} </span>
      {title}
    </Typography>
  );
  return (
    <Card className={classes.stepContent} elevation={0}>
      <CardHeader action={action} title={titleComponent} />
      <CardContent className={classes.content} ref={contentRef}>
        <div className={classes.inner}>{children}</div>
      </CardContent>
      <CardActions classes={{ root: classes.actions }}>{footer}</CardActions>
    </Card>
  );
};

StepWrapper.propTypes = {
  action: PropTypes.element,
  children: PropTypes.element.isRequired,
  contentRef: PropTypes.object,
  footer: PropTypes.element,
  step: PropTypes.number.isRequired,
  title: PropTypes.node.isRequired,
  titleComponents: PropTypes.element
};

export default StepWrapper;

import {makeStyles} from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

export const useStyles = makeStyles((theme) => ({
  root: {},
  statusContainer: {
    width: theme.spacing(2)
  },
  taskName: {
    color: theme.palette.secondary.main,
    width: theme.spacing(20)
  },
  taskDate: {
    color: grey[500],
    textAlign: 'right'
  },
  loaderShow: {
    background: 'rgba(255, 255, 255, 0.8)',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  loaderHide: {
    display: 'none'
  },
  row: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgb(237,247,252)!important'
    },
    '& > td': {
      padding: theme.spacing(1, 2),
      borderBottom: 'none'
    }
  },
  paginatorPart: {
    marginBottom: theme.spacing(5)
  },
  tablePart: {
    borderBottom: `2px solid ${grey[300]}`,
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    minHeight: 500
  }
}));

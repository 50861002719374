import React from 'react';
import BaseStatus from './BaseStatus';
import UpdateIcon from '@material-ui/icons/Update';
import styles from './index.module.css';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

const InProgress = ({ fill }) => {
  const {t} = useTranslation();
  return (
    <BaseStatus
      title={t('RUNNING')}
      fill={!!fill}
      className={styles.inProgress}
      fillClassName={styles.inProgress}>
      <UpdateIcon />
    </BaseStatus>
  );
};

InProgress.propTypes = {
  fill: PropTypes.bool,
};

export default InProgress;

import * as CONSTS from './consts';
import { getDomainSelector } from '../utils/fetch';
import { getListPayload } from '../../utils/paginator';
import { fromPairs } from '../../utils/tools';
import { addTimeDiff } from '../../utils/taskDataUtils';
import { matrixListSelector } from '../matrix/selectors';

const listPath = [CONSTS.DOMAIN, CONSTS.LIST_NAME];
const currentPath = [CONSTS.DOMAIN, CONSTS.CURRENT_NAME];
const aggregatedListPath = [CONSTS.DOMAIN, CONSTS.AGGREGATED_LIST_NAME];
const ttsListPath = [CONSTS.DOMAIN, CONSTS.FILTERED_LIST_NAME];
const tveListPath = [CONSTS.DOMAIN, CONSTS.TVE_LIST_NAME];

const _tasksListSelector = getDomainSelector(listPath, null);
const _aggregatedTasksListSelector = getDomainSelector(
  aggregatedListPath,
  null
);
const _ttsTasksListSelector = getDomainSelector(ttsListPath, null);
const _tveTasksListSelector = getDomainSelector(tveListPath, null);

export const taskListSelector = state => {
  const { payload, isFetching, isFetched } = _tasksListSelector(state);
  return {
    isFetching,
    isFetched,
    ...getListPayload(payload || {}),
    itemsById:
      payload && payload.results && payload.results.length
        ? fromPairs(payload.results.map(i => [i.id, i]))
        : []
  };
};

export const taskSelector = getDomainSelector(currentPath, {});

export function getSeries(task) {
  return task.metrics.map(metric => [metric.timeDiff, metric.energy]);
}

function getTensors(datasets) {
  const tensors = [];
  datasets.length &&
    datasets.forEach(dataset => {
      dataset &&
        dataset.tensors &&
        dataset.tensors.length &&
        dataset.tensors.forEach(tensorId => {
          if (tensors.indexOf(tensorId) === -1) {
            tensors.push(tensorId);
          }
        });
    });
  return tensors;
}

function getTemplates(tasks) {
  const templates = [];
  tasks.length &&
    tasks.forEach(task => {
      if (
        templates.filter(
          template => task.templates[0] && task.templates[0].id === template.id
        ).length === 0
      ) {
        task.templates[0] && templates.push(task.templates[0]);
      }
    });

  return templates;
}

function getTemplateTasks(tasks, template) {
  return tasks.filter(
    task => task.templates[0] && task.templates[0].id === template.id
  );
}

function filterTasksByDimension(tasks, dimension, matrixSelector) {
  return tasks.filter(task => {
    const taskDimension =
      matrixSelector.itemsById &&
      matrixSelector.itemsById[task.tensor_id] &&
      matrixSelector.itemsById[task.tensor_id].dimension;
    return taskDimension === dimension;
  });
}

export const aggregatedTaskListSelector = state => {
  const { isFetching, isFetched, payload } = _aggregatedTasksListSelector(
    state
  );

  return {
    isFetching,
    isFetched,
    items: payload && payload.tasks ? payload.tasks : [],
    itemsById:
      payload && payload.tasks
        ? fromPairs(payload.tasks.map(i => [i.id, i]))
        : [],
    tensors: payload && payload.tensors ? payload.tensors : [],
    backends: payload && payload.backends ? payload.backends : [],
    map: payload && payload.map ? payload.map : [],
    mapPending: payload && payload.map_pending ? payload.map_pending : [],
    notEnoughData:
      payload && payload.not_enough_data ? payload.not_enough_data : [],
    grandTotal: payload && payload.grand_total ? payload.grand_total : []
  };
};

export const ttsDimensionTaskListSelector = state => {
  const { payload, isFetching, isFetched } = _ttsTasksListSelector(state);

  let tensors = [];
  let series = [];
  let tdPayload = [];
  let dimensions = [];
  if (payload) {
    const matrixSelector = matrixListSelector(state);
    const prepareArray = [];
    let timeDiff = null;
    tdPayload = addTimeDiff(payload.tasks, prepareArray, timeDiff).sort(
      (a, b) => {
        const aDimension =
          matrixSelector.itemsById &&
          matrixSelector.itemsById[a.tensor_id] &&
          matrixSelector.itemsById[a.tensor_id].dimension;
        const bDimension =
          matrixSelector.itemsById &&
          matrixSelector.itemsById[b.tensor_id] &&
          matrixSelector.itemsById[b.tensor_id].dimension;
        return aDimension < bDimension ? -1 : aDimension === bDimension ? 0 : 1;
      }
    );
    tensors = getTensors(payload.datasets);

    dimensions = (() => {
      const results = [];
      tdPayload.forEach(task => {
        const dimension =
          matrixSelector.itemsById &&
          matrixSelector.itemsById[task.tensor_id] &&
          matrixSelector.itemsById[task.tensor_id].dimension;
        if (dimension) {
          if (results.indexOf(dimension) === -1) {
            results.push(dimension);
          }
        }
      });
      return results;
    })();

    const templates = getTemplates(tdPayload);

    series = (() => {
      const results = [];
      templates.forEach(template => {
        const tasks = getTemplateTasks(tdPayload, template);
        const resultRow = [];
        dimensions.forEach(dimension => {
          const oneDimensionTasks = filterTasksByDimension(
            tasks,
            dimension,
            matrixSelector
          );
          let totalTime = 0;
          let counter = 1;
          oneDimensionTasks.forEach(task => {
            const timeDiff =
              task.metrics &&
              task.metrics.length &&
              task.metrics.length > 0 &&
              task.metrics[task.metrics.length - 1].timeDiff;
            if (timeDiff) {
              totalTime += timeDiff;
              counter += 1;
            }
          });
          resultRow.push([dimension, totalTime / counter]);
        });

        results.push({
          data: resultRow,
          type: 'bar',
          name: template.name,
          id: `s${template.id}`
        });
      });

      return results;
    })();
  }

  return {
    isFetching,
    isFetched,
    tensors: tensors,
    items: tdPayload,
    series: series,
    categories: dimensions
  };
};

export const timeVsEnergyFilteredTasksListSelector = state => {
  const { payload, isFetching, isFetched } = _tveTasksListSelector(state);
  const prepareArray = [];
  let timeDiff = null;
  const tdPayload =
    payload && addTimeDiff(payload.tasks, prepareArray, timeDiff);
  const tensors = tdPayload ? getTensors(payload.datasets) : [];
  const sPayload = tdPayload
    ? tdPayload.map(task =>
        Object.assign({}, task, {
          series: task.metrics.map(metric => [metric.timeDiff, metric.energy])
        })
      )
    : [];

  return {
    isFetching,
    isFetched,
    items: sPayload,
    tensors: tensors,
    itemsById:
      sPayload && sPayload.length ? fromPairs(sPayload.map(i => [i.id, i])) : []
  };
};

export const getMapItemsCount = pendingMap => {
  let count = 0;
  pendingMap.forEach(raw => {
    raw.forEach(cell => {
      if (cell) count += 1;
    });
  });
  return count;
};

export const isPending = (pendingMap, x, y) => {
  return !!pendingMap[y][x];
};

export const getMapItem = (map, x, y) => {
  return map[y][x];
};

export const getMapItems = map => {
  let items = [];
  map.forEach(raw => {
    raw.forEach(cell => {
      if (cell) items.push(cell);
    });
  });
  return items;
};

import * as CONSTS from '../templates/consts';
import * as api from '../api';
import { select, takeLatest } from '@redux-saga/core/effects';
import {allTemplatesListSelector, templatesListSelector} from './selectors';
import { getFetchSaga } from '../utils/fetch';
import { put } from 'redux-saga/effects';
import { showAlertMessageAction } from '../alerts/actions';
import findIndex from 'lodash/findIndex'

const createTemplate = {
  type: CONSTS.FETCH_LIST,
  apiMethod: api.createTemplate,
  handleSuccess: function*(data) {
    const { result, ...props } = yield select(templatesListSelector);
    return { result: [data, ...result], ...props };
  }
};

const createTemplatePushed = {
  type: CONSTS.FETCH_ALL_LIST,
  apiMethod: api.createTemplate,
  handleSuccess: function*(data) {
    const message = {
      text: 'Template preset successfully.',
      severity: 'success'
    };

    yield put(showAlertMessageAction(message));
    const { payload } = yield select(allTemplatesListSelector);
    if (payload && payload.results) {
      const { count, results, pages, page } = payload
      return {
        results: page === 1 ? [data.data, ...results.slice(0, -1)] : results,
        count: count + 1,
        pages,
        page
      } ;
    } else {
      return {count: 1, results: [data.data], pages: 1, page: 1};
    }
  }
};

const listTemplates = {
  type: CONSTS.FETCH_LIST,
  apiMethod: api.listTemplates
};

const deleteTemplate = {
  type: CONSTS.FETCH_LIST,
  apiMethod: api.deleteTemplate,
  handleSuccess: function*(_, { templateId }) {
    const { payload } = yield select(templatesListSelector);
    return payload.filter(i => i.id !== templateId);
  }
};

const updateTemplate = {
  type: CONSTS.FETCH_LIST,
  apiMethod: api.updateTemplate,
  handleSuccess: function*({ data }) {
    const message = {
      text: 'Template updated successfully.',
      severity: 'success'
    };
    yield put(showAlertMessageAction(message));
    const { payload } = yield select(allTemplatesListSelector);
    if (payload && payload.results) {
      const {count, results, pages, page} = payload
      const index = findIndex(results, ({ id }) => data.id === id)
      if (index !== -1) results[index] = data
      return {results: [...results], count, pages, page};
    }
    else {
      return {count: 1, results: [data], pages: 1, page: 1};
    }
  }
};

const listAllTemplates = {
  type: CONSTS.FETCH_ALL_LIST,
  apiMethod: api.listAllTemplates
};

export default function*() {
  yield takeLatest(CONSTS.FETCH_CREATE, getFetchSaga(createTemplate));
  yield takeLatest(
    CONSTS.FETCH_CREATE_PUSHED,
    getFetchSaga(createTemplatePushed)
  );
  yield takeLatest(CONSTS.FETCH_UPDATE, getFetchSaga(updateTemplate));
  yield takeLatest(CONSTS.LIST, getFetchSaga(listTemplates));
  yield takeLatest(CONSTS.FETCH_DELETE, getFetchSaga(deleteTemplate));
  yield takeLatest(CONSTS.ALL_LIST, getFetchSaga(listAllTemplates));
}

import fromPairs from 'lodash/fromPairs'


export const TASK_STATUS = {
  CREATED: 10,
  RUNNING: 20,
  CANCELED: 30,
  ERROR: 40,
  SUCCESS: 50,
  PENDING: 60,
  SENDING: 61,
  FINISH: 70,
  QUEUE: 80,
};

export const NEW_TASKS = [TASK_STATUS.SENDING, TASK_STATUS.PENDING, TASK_STATUS.CREATED, TASK_STATUS.QUEUE];
export const RUNNING_TASKS = [TASK_STATUS.RUNNING];
export const COMPLETED_TASKS = [TASK_STATUS.SUCCESS, TASK_STATUS.ERROR, TASK_STATUS.CANCELED];

export const taskIsFinished = ({ status }) =>
  [TASK_STATUS.CANCELED, TASK_STATUS.ERROR, TASK_STATUS.SUCCESS, TASK_STATUS.FINISH].indexOf(status) !== -1
export const statusNames = fromPairs(Object.keys(TASK_STATUS).map( i => [TASK_STATUS[i], i]))

export const DOMAIN = 'TASKS';
export const CURRENT_NAME = 'CURRENT';
export const LIST_NAME = 'LIST';

export const FETCH_LIST = `${DOMAIN}@FETCH`;
export const FETCH_CURRENT = `${DOMAIN}@CURRENT`;
export const FETCH_STOP = `${DOMAIN}@STOP`;
export const FETCH_CREATE = `${DOMAIN}@CREATE`;
export const FETCH_BULK_CREATE = `${DOMAIN}@BULK_CREATE`;
export const CURRENT = `${DOMAIN}@${CURRENT_NAME}`;
export const LIST = `${DOMAIN}@${LIST_NAME}`;
export const BULK = `${DOMAIN}@${FETCH_BULK_CREATE}`;

export const FILTERED_LIST_NAME = 'FILTERED_LIST';
export const FILTERED_LIST = `${DOMAIN}@${FILTERED_LIST_NAME}`;
export const FETCH_FILTERED = `${DOMAIN}@FILTERED`;

export const AGGREGATED_LIST_NAME = 'AGGREGATED_LIST';
export const AGGREGATED_LIST = `${DOMAIN}@${AGGREGATED_LIST_NAME}`;
export const FETCH_AGGREGATED = `${DOMAIN}@AGGREGATED`;

export const TVE_LIST_NAME = 'TVE_LIST';
export const TVE_LIST = `${DOMAIN}@${TVE_LIST_NAME}`;
export const FETCH_TVE = `${DOMAIN}@TVE`;

export const BACKEND_ADIABATIC_TYPE = 1;
export const BACKEND_GATE_TYPE = 3;

import * as CONSTS from './consts';
import { getDomainSelector } from '../utils/fetch';
import { fromPairs } from '../../utils/tools';

const listPath = [CONSTS.DOMAIN, CONSTS.LIST_NAME];

export const templatesListSelector = getDomainSelector(listPath, null);

export const listSelector = state => {
  const { payload, isFetching, isFetched } = templatesListSelector(state);
  return { isFetching, isFetched, items: payload || [] };
};

const allListPath = [CONSTS.DOMAIN, CONSTS.ALL_LIST_NAME];

export const allTemplatesListSelector = getDomainSelector(allListPath, null);

export const allListSelector = state => {
  const { payload, isFetching, isFetched } = allTemplatesListSelector(state);
  return {
    isFetching,
    isFetched,
    items: payload || [],
    itemsById: payload ? fromPairs(payload.map(i => [i.id, i])) : []
  };
};

import * as React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Field } from 'react-final-form';
import {FormHelperText} from '@material-ui/core';
import PropTypes from 'prop-types';


const DatePickerField = ({ label, name, disabled, helperText }) =>{

  return (
    <Field
      format={value => value || []}
      multiple
      name={name}
    >
      {({ input, meta }) => {
        return <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            format="MM/dd/yyyy"
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            label={label}
            margin="normal"
            {...input}
            disabled={disabled}
            variant="contained"
          />
          <FormHelperText>{meta.error || helperText}</FormHelperText>
        </MuiPickersUtilsProvider>
      }}
    </Field>
  );
}

DatePickerField.propTypes = {
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired
};


export default DatePickerField;
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { meSelector } from '../../core/users/selectors';
import { Redirect, useHistory } from 'react-router';
import TopBar from '../../components/Bars/TopBar';
import { Button, Container, Typography } from '@material-ui/core';
import { Form } from 'react-final-form';
import { String } from '../../components/FormFields';
import { getValidators, isRequired } from '../../utils/validators';
import { useTranslation } from 'react-i18next';
import Link from '@material-ui/core/Link';
import { getLocation } from '../../Routes';
import Checkbox from '../../components/FormFields/Checkbox';
import BottomBar from '../../components/Bars/BottomBar';
import { showAlertMessageAction } from '../../core/alerts/actions';
import { signUp } from '../../core/api';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.primary.main
  },
  headerItem: {
    color: theme.palette.secondary.main,
    padding: `0 0 ${theme.spacing(2)}px 0`,
  },
  subHeaderItem: {
    color: theme.palette.secondary.contrastText,
    padding: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px 0`,
  },
  signInLink: {
    color: theme.palette.secondary.main,
    marginLeft: `${theme.spacing(0.7)}px`,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
    '& > div': {
      marginBottom: theme.spacing(0),
      marginTop: theme.spacing(0)
    },
    '& label.Mui-focused, label': {
      color: theme.palette.common.white
    },
    '& label.MuiInputLabel-shrink': {
      transform: 'translate(14px, -15px) scale(0.75)'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.36)'
      },
      '&:hover fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.36)'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.36)'
      },
      '& input': {
        color: theme.palette.common.white
      }
    }
  },
  submit: {
    margin: theme.spacing(1, 0, 1),
    fontSize: 14,
    fontWeight: 600,
    padding: theme.spacing(1),
    color: theme.palette.primary.main,
    minWidth: '120px',
    maxWidth: '150px',
    float: 'right',
    textTransform: 'inherit'
  },
  email: {
    '& .MuiFormHelperText-root': {
      color: '#bdbdbd',
      margin: theme.spacing(0.3, 0, 1, 0)
    }
  },
  consent: {
    margin: theme.spacing(0),
    '& .MuiCheckbox-colorPrimary': {
      padding: theme.spacing(0, 1, 0, 1),
      color: `${theme.palette.secondary.main}!important`
    },
    '& .MuiTypography-body1': {
      fontSize: 12,
      color: `${theme.palette.secondary.main}!important`
    }
  },
  social: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 0, 1, 0)
  },
  socialText: {
    fontSize: 12,
    color: theme.palette.secondary.main,
    width: '50%'
  },
  socialIcon: {
    width: '50%',
    color: theme.palette.secondary.contrastText,
  },
  faceBookIcon: {

  },
  githubIcon: {

  },
  container: {
    zIndex: theme.zIndex.drawer + 5
  }
}));

const validators = getValidators({
  first_name: [isRequired],
  last_name: [isRequired],
  company: [isRequired],
  position: [isRequired],
  email: [isRequired],
  password: [isRequired],
  consent: [isRequired],
});

const SignUp = () => {
  const classes = useStyles();
  const {t} = useTranslation();
  const { payload: user, isFetching } = useSelector(meSelector);
  const loc = getLocation();
  const history = useHistory();
  const signInClick = (e) => {
    e.stopPropagation();
    history.push(loc.SignIn());
  };
  const dispatch = useDispatch()

  const onSubmit = (params) => {
    signUp(params).then(() => {
      history.push(loc.SignIn());
      const message = {
        text: t('SuccessSignUp'),
        severity: 'success'
      };
      dispatch(showAlertMessageAction(message));
    }).catch((error) => {
      let errorText = t('SignUp error')
      if (error.response.data && error.response.data.username) {
        errorText = t(error.response.data.username);
      }
      const message = {
        text: errorText,
        severity: 'error'
      };
      dispatch(showAlertMessageAction(message));
    })
  };

  if (user) {
    return <Redirect to="/" />;
  } else {
    return (
      <div className={classes.root}>
        <TopBar type={'sign-up'}/>
        <Container
          className={classes.container}
          component="main"
          maxWidth="xs"
        >
          <div className={classes.header}>
            <Typography
              className={classes.headerItem}
              component="h1"
              variant="h3"
            >
              {t('signUp')}
            </Typography>
            <Typography
              className={classes.subHeaderItem}
            >
              {t('alreadyHaveAnAccount')}
              <Link
                className={classes.signInLink}
                href={loc.SignIn()}
                onClick={signInClick}
              >
                {t('signIn')}
              </Link>
            </Typography>
          </div>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form
                className={classes.form}
                onSubmit={handleSubmit}
              >
                <String
                  fullWidth
                  label={t('Login')}
                  margin="dense"
                  name="username"
                />
                <String
                  fullWidth
                  label={t('firstName')}
                  margin="dense"
                  name="first_name"
                />
                <String
                  fullWidth
                  label={t('lastName')}
                  margin="dense"
                  name="last_name"
                />
                <String
                  fullWidth
                  label={t('company')}
                  margin="dense"
                  name="company"
                />
                <String
                  fullWidth
                  label={t('position')}
                  margin="dense"
                  name="position"
                />
                <String
                  fullWidth
                  className={classes.email}
                  helperText={t('wellNeverShareYourEmailWithAnyoneElse')}
                  label={t('emailAddress')}
                  margin="dense"
                  name="email"
                  type="email"
                />
                <String
                  fullWidth
                  label={t('password')}
                  margin="dense"
                  name="password"
                  type="password"
                />
                <Checkbox
                  className={classes.consent}
                  items={[{title: t('consentToTheProcessingOfPersonalData'), value: false}]}
                  margin="dense"
                  name="consent"
                />
                <Button
                  className={classes.submit}
                  color="secondary"
                  disabled={isFetching}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {t('signUp')}
                </Button>
              </form>
            )}
            validate={validators}
          />
        </Container>
        <BottomBar />
      </div>
    );
  }
};

export default SignUp;

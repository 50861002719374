import {delay, putResolve, select, takeLatest, takeLeading, put} from '@redux-saga/core/effects';
import * as CONSTS from './consts';
import connect from './connect';
import {taskListSelector, taskSelector} from '../tasks/selectors';
import {findIndex, get} from '../../utils/tools';
import {getLocation} from '../../Routes';
import {successSaga} from '../utils/fetch';
import {fetchTasks, updateCurrent, updateList} from '../tasks/actions';
import {serverSelector} from '../servers/selectors';
import {updateUtilization} from '../servers/actions';
import {COMPLETED_TASKS, NEW_TASKS, RUNNING_TASKS} from '../tasks/consts';
import {
  getSpecifyingParameters,
  isAddOrInsert,
  isEmptyList, isIgnore,
  isListedOnTheFirstPage,
  isNeedToUpdate,
  isTaskIdBelowAllElements,
  isTheTaskIdIsAboveAllElements,
  searchIndexToInsert
} from './helpers';
import {saveMessages} from "./actions";
import {meSelector} from "../users/selectors";

const RECONNECT_DELAY = 5000;
const MAX_RECONNECT_COUNT = 12;

const runConnect = function*({ payload }) {
  if (payload) {
    console.log(`Reconnect to notifications after ${RECONNECT_DELAY / 1000}s`);
    yield delay(RECONNECT_DELAY);
  }
  // TODO: need drop counter when success connect
  if (payload <= MAX_RECONNECT_COUNT) connect(payload);
};


const updateTasksList = function*(taskId, data, statuses) {
  const state = yield select(taskListSelector);
  const status = data.status;
  const [userId, serverId, page, pageSize] = getSpecifyingParameters();
  const items = state.items || [];
  const taskIndex = findIndex(items, { id: taskId });
  const isInList = taskIndex !== -1;
  const count = state.count;

  const actionTypes = {
    updateState: 'updateState',
    edit: 'edit',
    insert: 'insert',
    delete: 'delete'
  }

  const addOrInsert = isAddOrInsert(statuses, status); // true - add or edit, false - delete item

  const isUpdateState = () => {
    return isNeedToUpdate(page, pageSize, count, statuses, status,
      taskId, addOrInsert, items, taskIndex, isInList);
  };
  const isEditList = () => {
    return !isNeedToUpdate(page, pageSize, count, statuses, status,
      taskId, addOrInsert, items, taskIndex, isInList) && addOrInsert && isInList;
  };
  const isInsertToList = () => {
    return !isNeedToUpdate(page, pageSize, count, statuses, status,
      taskId, addOrInsert, items, taskIndex, isInList) && addOrInsert && !isInList;
  };
  const isDeleteFromList = () => {
    return !isNeedToUpdate(page, pageSize, count, statuses, status,
      taskId, addOrInsert, items, taskIndex, isInList) && !addOrInsert;
  };

  const defineActionTypes = {
    [actionTypes.updateState]: isUpdateState,
    [actionTypes.edit]: isEditList,
    [actionTypes.insert]: isInsertToList,
    [actionTypes.delete]: isDeleteFromList
  };

  const getActionType = () => {
    let action;
    for (const key in defineActionTypes) {
      if (defineActionTypes[key]()) {
        action = key;
        break;
      }
    }
    return action;
  };

  const actionTypesHandlers = function* (actionType) {

    let resultArray = [];

    switch (actionType) {
    case actionTypes.updateState:
      yield putResolve(fetchTasks(page, pageSize, userId, serverId, statuses, null));
      break;
    case actionTypes.edit:
      items[taskIndex] = { id: taskId, ...data };
      yield successSaga(updateList([...items], count, pageSize));
      break;
    case actionTypes.insert:
      if (isEmptyList(items)) {
        resultArray = [{ id: taskId, ...data }]
      } else {
        const isFullList = items.length === pageSize;
        if (isTaskIdBelowAllElements(items, taskId)) {
          if (isFullList) {
            const bufArray = items.slice();
            bufArray.splice(pageSize - 1, 1);
            resultArray = [ ...bufArray, { id: taskId, ...data }];
          } else {
            resultArray = [ ...items, { id: taskId, ...data }];
          }
        }
        else if (isTheTaskIdIsAboveAllElements(items, taskId)) {
          if (isFullList) {
            const bufArray = items.slice();
            bufArray.splice(pageSize - 1, 1);
            resultArray = [ { id: taskId, ...data }, ...bufArray];
          } else {
            resultArray = [ { id: taskId, ...data }, ...items];
          }
        } else {
          const searchIndex = searchIndexToInsert(items, taskId);
          resultArray = [
            ...items.slice(0, searchIndex),
            { id: taskId, ...data },
            ...items.slice(searchIndex)
          ]
        }
      }
      yield successSaga(updateList(resultArray, count + 1, pageSize));
      break;
    case actionTypes.delete:
      if (isListedOnTheFirstPage(isInList, page, pageSize, items.length)) {
        let resultList = items.slice();
        if (taskIndex > -1) {
          resultList.splice(taskIndex, 1);
        }
        yield successSaga(updateList(resultList, count - 1, pageSize));
      }
      break;
    default:
      break;
    }
  };

  const action = getActionType();

  yield actionTypesHandlers(action);
};

const updateCurrentTask = function*(taskId, data, type) {
  const state = yield select(taskSelector);
  const { payload } = state;
  let update;
  if (type === CONSTS.TYPE.STATUS) {
    update = data;
  } else if (type === CONSTS.TYPE.RESULT) {
    update = data;
  } else if (type === CONSTS.TYPE.LOG) {
    update = { log: payload.log ? [...payload.log, data] : [data] };
  } else if (type === CONSTS.TYPE.METRIC) {
    update = { metrics: [...get(payload, 'metrics', []), data] };
  } else {
    update = {};
  }
  yield successSaga(updateCurrent(payload, update));
};

const updateServerUtilization = function*(data, loc) {
  const { payload } = yield select(serverSelector);
  if (loc.isServersList() && data.server_id === (payload || {}).id) {
    delete data.server_id;
    yield successSaga(updateUtilization(payload, data));
  }
};


const receiveMessage = function*({ payload }) {

  const { task_id: taskId, type, payload: data } = payload;

  const loc = getLocation();

  if (type === CONSTS.TYPE.STATUS) {
    const status = data.status;
    const currentLocation = loc.current();

    const messageActionsMap = {
      [loc.NewTasks()]:
        [isIgnore(status, COMPLETED_TASKS), NEW_TASKS],
      [loc.InProgressTasks()]:
        [isIgnore(status, NEW_TASKS), RUNNING_TASKS],
      [loc.CompletedTasks()]:
        [isIgnore(status, [...NEW_TASKS, ...RUNNING_TASKS]), COMPLETED_TASKS]
    };

    if (messageActionsMap[currentLocation]) {
      const [isIgnore, statuses] = messageActionsMap[currentLocation];
      if (!isIgnore) yield updateTasksList(taskId, data, statuses);
    }

  } else if (type === CONSTS.TYPE.UTILIZATION) {
    yield updateServerUtilization(data, loc);
  } else if (loc.isTask() && (taskId || -1).toString() === loc.parseTaskId()) {
    yield updateCurrentTask(taskId, data, type);
  }

  const { payload: me } = yield select(meSelector);
  if (me && me.is_staff) yield put(saveMessages(payload))

};

export default function*() {
  yield takeLatest(CONSTS.CONNECT_ACTION, runConnect);
  yield takeLeading(CONSTS.RECEIVE_ACTION, receiveMessage);
}

import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {useTranslation} from 'react-i18next';
import {ReactComponent as ConsultationIcon} from './icons/consultation.svg';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Dialog, DialogContent, DialogTitle} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {ReactComponent as CloseIcon} from './icons/close.svg';
import {Form} from 'react-final-form';
import {String} from './FormFields';
import Checkbox from './FormFields/Checkbox';
import {getValidators, isEmail, isNumber, isRequired} from '../utils/validators';
import {sendEmail} from '../core/api';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    color: theme.palette.secondary.main,
    border: `1.9px solid ${theme.palette.secondary.main}`,
    textTransform: 'inherit',
    borderRadius: '7px',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    margin: `0 ${theme.spacing(1)}px`,
    minWidth: '220px',
    fontWeight: 400,
    letterSpacing: '1px',
    fontFamily: 'Montserrat',
    whiteSpace: 'nowrap'
  },
  smButton: {
    margin: `0 ${theme.spacing(2)}px`,
    padding: theme.spacing(0.5, 2)
  },
  buttonMobile: {
    letterSpacing: 'inherit',
    fontSize: 12,
    minWidth: 'inherit',
    padding: theme.spacing(0, 2)
  },
  buttonItem: {
    border: 'none',
    margin: theme.spacing(0, 0, 0, 2),
    padding: theme.spacing(1, 0),
    color: theme.palette.secondary.main,
    textTransform: 'inherit',
    fontSize: 19
  },
  iconMobile: {
    width: '18px!important'
  },
  lightButton: {
    color: theme.palette.secondary.contrastText + '!important',
    borderColor: theme.palette.secondary.contrastText + '!important'
  },
  icon: {
    width: '22px',
    marginRight: theme.spacing(1),
    strokeWidth: '0.4px',
  },
  dark: {
    fill: theme.palette.secondary.main,
    stroke: theme.palette.secondary.main
  },
  light: {
    fill: theme.palette.secondary.contrastText,
    stroke: theme.palette.secondary.contrastText
  },
  smFontSize: {
    fontSize: '16px'
  },
  accessIcon: {
    strokeWidth: '0.2px',
    stroke: theme.palette.secondary.contrastText,
    fill: theme.palette.secondary.contrastText,
    marginRight: `${theme.spacing(2)}px`,
    width: '34px'
  },
  closeIcon: {
    strokeWidth: '0.2px',
    stroke: theme.palette.secondary.main,
    fill: theme.palette.secondary.main,
    width: '18px'
  },
  accessHeader: {
    color: theme.palette.secondary.contrastText,
    display: 'flex',
    fontSize: '28px',
    fontFamily: 'Montserrat',
    letterSpacing: 0,
    marginBottom: theme.spacing(4),
    whiteSpace: 'nowrap'
  },
  accessHeaderMobile: {
    fontSize: '21px'
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(0.5, 1)
  },
  dialog: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: 0,
      border: '1px solid #2a1f50'
    }
  },
  dialogContent: {
    padding: theme.spacing(1, 15, 4)
  },
  dialogContentMobile: {
    padding: theme.spacing(1, 2, 4)
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
    '& > div': {
      marginBottom: theme.spacing(0),
      marginTop: theme.spacing(0)
    },
    '& label.Mui-focused, label': {
      color: theme.palette.common.white
    },
    '& label.MuiInputLabel-shrink': {
      transform: 'translate(14px, -15px) scale(0.75)'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.36)'
      },
      '&:hover fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.36)'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.36)'
      },
      '& input': {
        color: theme.palette.common.white
      },
      '& textarea': {
        color: theme.palette.common.white
      }
    }
  },
  submit: {
    margin: theme.spacing(1, 0, 1),
    fontSize: 16,
    fontWeight: 300,
    padding: theme.spacing(1, 1),
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    border: ` 1px solid ${theme.palette.secondary.main}`,
    minWidth: '100px',
    maxWidth: '130px',
    float: 'right',
    textTransform: 'inherit'
  },
  remember: {
    margin: theme.spacing(0),
    '& .MuiCheckbox-colorPrimary': {
      padding: theme.spacing(0, 1, 0, 1),
      color: `${theme.palette.secondary.contrastText}!important`
    },
    '& .MuiTypography-body1': {
      fontSize: 14,
      color: `${theme.palette.secondary.contrastText}!important`
    }
  },
  submitRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  hidden: {
    display: 'none'
  }
}));

const validators = getValidators({
  name: [isRequired],
  subject: [isRequired],
  email: [isRequired, isEmail],
  phone: [isRequired, isNumber],
  consent: [isRequired]
});

const OrderConsultation = ({color, size, menuItem, isMobile}) => {
  const classes = useStyles();
  const {t} = useTranslation();
  const [open, setOpen] = useState(false);
  const toggleOpen = () =>{setOpen(!open)}
  const [send, setSend] = useState(false)
  const handleClickOpen = () => {
    toggleOpen();
    setSend(false)
  };

  const handleClose = () => {
    toggleOpen();
  };

  const onSubmit = params => {
    sendEmail({...params, type: 'Order consultation'}).then(() => {}).catch(() => {})
    setSend(true)
  };


  return (
    <div className={classes.root}>
      <Button
        className={clsx(menuItem ? classes.buttonItem : classes.button,
          !menuItem && color === 'light' && classes.lightButton,
          !menuItem && size === 'md' && classes.smFontSize,
          !menuItem && size === 'md' && classes.smButton,
          !menuItem && size === 'sm' && classes.buttonMobile
        )}
        onClick={handleClickOpen}
      >
        <ConsultationIcon
          className={clsx(classes.icon,
            !menuItem && size === 'sm' && classes.iconMobile,
            !menuItem && color === 'light' ? classes.light : classes.dark )}
        />
        {t('orderConsultation')}
      </Button>
      <Dialog
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        className={classes.dialog}
        fullWidth
        maxWidth={'sm'}
        onClose={handleClose}
        open={open}
      >
        <DialogTitle
          className={classes.header}
          disableTypography
          id="order-consultation"
        >
          <IconButton onClick={handleClose}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          className={clsx(classes.dialogContent, isMobile && classes.dialogContentMobile)}
        >
          <div
            className={clsx(classes.accessHeader, isMobile && classes.accessHeaderMobile)}
          >
            {!send && <ConsultationIcon
              className={classes.accessIcon}
            />}
            {send ? t('Thanks for your request. We will contact you shortly') : t('consultationOrdering')}
          </div>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form
                className={clsx(classes.form, send && classes.hidden)}
                onSubmit={handleSubmit}
              >
                <String
                  autoComplete="name"
                  fullWidth
                  label={t('yourName')}
                  margin="dense"
                  name="name"
                />
                <String
                  autoComplete="subject"
                  fullWidth
                  label={t('subject')}
                  margin="dense"
                  name="subject"
                />
                <String
                  autoComplete="phone"
                  fullWidth
                  label={t('phone')}
                  margin="dense"
                  name="phone"
                />
                <String
                  autoComplete="email"
                  fullWidth
                  label={t('emailAddress')}
                  margin="dense"
                  name="email"
                />

                <Checkbox
                  className={classes.remember}
                  items={[{title: t('consent'), value: false}]}
                  margin="dense"
                  name="consent"
                />
                <div className={classes.submitRow}>

                  <Button
                    className={classes.submit}
                    color="secondary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {t('order')}
                  </Button>
                </div>
              </form>
            )}
            validate={validators}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

OrderConsultation.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  menuItem: PropTypes.bool,
  isMobile: PropTypes.bool
};

export default OrderConsultation;

import { combineReducers } from 'redux';

import * as CONSTS from './consts';
import { getFetchReducer } from '../utils/fetch';

export default {
  [CONSTS.DOMAIN]: combineReducers({
    [CONSTS.LIST_NAME]: getFetchReducer(CONSTS.FETCH_LIST),
    [CONSTS.ALL_LIST_NAME]: getFetchReducer(CONSTS.FETCH_ALL_LIST)
  })
};

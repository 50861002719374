import React from 'react';
import { Form } from 'react-final-form';
import { Button, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { meSelector } from '../core/users/selectors';
import { Redirect, useHistory, useLocation } from 'react-router';
import { useAction } from '../utils/hooks';
import { fetchLoginAction } from '../core/users/actions';
import { getValidators, isRequired } from '../utils/validators';
import { String } from '../components/FormFields';
import TopBar from '../components/Bars/TopBar';
import Link from '@material-ui/core/Link';
import {useTranslation} from 'react-i18next';
import {getLocation} from '../Routes';
import Checkbox from '../components/FormFields/Checkbox';
import FacebookIcon from '@material-ui/icons/Facebook';
import BottomBar from '../components/Bars/BottomBar';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.primary.main
  },
  headerItem: {
    color: theme.palette.secondary.main,
    padding: `0 0 ${theme.spacing(2)}px 0`
  },
  subHeaderItem: {
    color: theme.palette.secondary.contrastText,
    padding: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px 0`,
  },
  signUpLink: {
    color: theme.palette.secondary.main,
    marginLeft: `${theme.spacing(0.7)}px`,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
    '& > div': {
      marginBottom: theme.spacing(0),
      marginTop: theme.spacing(0)
    },
    '& label.Mui-focused, label': {
      color: theme.palette.common.white
    },
    '& label.MuiInputLabel-shrink': {
      transform: 'translate(14px, -15px) scale(0.75)'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.36)'
      },
      '&:hover fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.36)'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.36)'
      },
      '& input': {
        color: theme.palette.common.white
      }
    }
  },
  submit: {
    margin: theme.spacing(1, 0, 1),
    fontSize: 14,
    fontWeight: 600,
    padding: theme.spacing(1),
    color: theme.palette.primary.main,
    minWidth: '120px',
    maxWidth: '150px',
    float: 'right',
    textTransform: 'inherit'
  },
  remember: {
    margin: theme.spacing(0),
    '& .MuiCheckbox-colorPrimary': {
      padding: theme.spacing(0, 1, 0, 1),
      color: `${theme.palette.secondary.contrastText}!important`
    },
    '& .MuiTypography-body1': {
      fontSize: 14,
      color: `${theme.palette.secondary.contrastText}!important`
    }
  },
  submitRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  forgotLink: {
    color: `${theme.palette.secondary.main}!important`,
    fontSize: 14,
    float: 'left'
  },
  social: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 0, 1, 0)
  },
  socialText: {
    fontSize: 12,
    color: theme.palette.secondary.main,
    width: '50%'
  },
  socialIcon: {
    width: '50%',
    color: theme.palette.secondary.contrastText,
  },
  container: {
    marginTop: theme.spacing(10),
    zIndex: theme.zIndex.drawer + 5
  }
}));

const validators = getValidators({
  username: [isRequired],
  password: [isRequired]
});

const Login = () => {
  const classes = useStyles();
  const {t} = useTranslation();
  const login = useAction(fetchLoginAction);
  const loc = getLocation();
  const history = useHistory();

  const { payload: user, isFetching } = useSelector(meSelector);
  const onSubmit = ({ username, password }) => {
    login(username, password);
  };

  const signUpClick = (e) => {
    e.stopPropagation();
    history.push(loc.SignUp());
  };

  let location = useLocation();
  const { from } = location.state || { from: { pathname: loc.Dashboard() } };

  if (user) {
    return <Redirect to={from} />;
  } else {
    return (
      <div className={classes.root}>
        <TopBar type={'sign-in'} />
        <Container
          className={classes.container}
          component="main"
          maxWidth="xs"
        >
          <div className={classes.header}>
            <Typography
              className={classes.headerItem}
              component="h1"
              variant="h3"
            >
              {t('signIn')}
            </Typography>
            <Typography
              className={classes.subHeaderItem}
            >
              {t('newUserSignUpto')}
              <Link
                className={classes.signUpLink}
                href={loc.SignUp()}
                onClick={signUpClick}
              >
                {t('createYourAccount')}
              </Link>
            </Typography>
          </div>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form
                className={classes.form}
                onSubmit={handleSubmit}
              >
                <String
                  autoComplete="username"
                  fullWidth
                  label="Login"
                  name="username"
                  margin="dense"
                />
                <String
                  autoComplete="current-password"
                  fullWidth
                  label="Password"
                  name="password"
                  margin="dense"
                  type="password"
                />
                <Checkbox
                  className={classes.remember}
                  items={[{title: t('rememberMe'), value: false}]}
                  margin="dense"
                  name="remember"
                />
                <div className={classes.submitRow}>
                  <Link
                    className={classes.forgotLink}
                    href={'#'}
                  >
                    {t('forgotPassword')}?
                  </Link>
                  <Button
                    className={classes.submit}
                    color="secondary"
                    disabled={isFetching}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {t('signIn')}
                  </Button>
                </div>
              </form>
            )}
            validate={validators}
          />
        </Container>
        <BottomBar />
      </div>
    );
  }
};

export default Login;

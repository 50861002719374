import { clearSaga, fetchSaga, getFetchSaga } from '../utils/fetch';
import { put, select, takeLatest } from 'redux-saga/effects';
import * as CONSTS from './consts';
import * as api from '../api';
import { appLoaded } from '../app/actions';
import { notificationsConnect } from '../notifications/actions';
import { fetchMeAction } from './actions';
import { history } from '../../commons';
import { showAlertMessageAction } from '../alerts/actions';
import { meSelector } from './selectors';
import { getLocation } from '../../Routes';
import { fetchBackends } from '../backends/actions';

const fetchMeConfig = {
  type: CONSTS.FETCH_ME,
  apiMethod: api.fetchMe,
  handleSuccess: function*(response) {
    yield put(appLoaded());
    yield put(notificationsConnect(0));
    return response.data;
  },
  handleError: function*(e) {
    yield put(appLoaded());
    return e;
  }
};

const fetchLogin = {
  type: CONSTS.FETCH_ME,
  apiMethod: api.login,
  handleSuccess: function*(e) {
    const username = e && e.data && e.data.username;

    const message = {
      text: `Successful authorization. Greets you, ${username}.`,
      severity: 'info'
    };
    yield put(showAlertMessageAction(message));
    yield put(fetchMeAction());
    yield put(fetchBackends());

    return e.data;
  },
  handleError: function*(e) {
    const error_text = e.response.data ? e.response.data.detail: 'Invalid credentials, try again please'
    const message = {
      text: error_text,
      severity: 'error'
    };
    yield put(showAlertMessageAction(message));

    return e;
  }
};

const fetchLogout = {
  type: CONSTS.FETCH_ME,
  apiMethod: api.logout,
  handleSuccess: function*() {
    const message = {
      text: 'Logged Out',
      severity: 'info'
    };
    yield put(showAlertMessageAction(message));
    return null;
  },
  handleError: function*(e) {
    yield put(clearSaga(fetchMeAction()));
    return e;
  }
};

const getUsers = {
  type: CONSTS.FETCH_LIST,
  apiMethod: api.listUsers
};

const createUser = {
  type: CONSTS.FETCH_CURRENT,
  apiMethod: api.createUser,

  handleSuccess: function*(e) {
    const username = e && e.data && e.data.username;
    const message = {
      text: `${username} created successfully.`,
      severity: 'success'
    };
    yield put(showAlertMessageAction(message));
    history.push(getLocation().Management());

    return { data: null };
  },
  handleError: function*(e) {
    const message = {
      text: 'User create error. Try again.',
      severity: 'error'
    };
    yield put(showAlertMessageAction(message));
    return e;
  }
};

const getUser = {
  type: CONSTS.FETCH_CURRENT,
  apiMethod: api.fetchUser
};

const deleteUser = {
  type: CONSTS.FETCH_CURRENT,
  apiMethod: api.deleteUser,
  handleSuccess: function() {
    history.push('/users');
    return { data: null };
  }
};

const updateUser = {
  type: CONSTS.FETCH_CURRENT,
  apiMethod: api.updateUser,

  handleSuccess: function*(e) {
    const username = e && e.data && e.data.username;
    const userId = e && e.data && e.data.id;
    const message = {
      text: `${username} profile data has been updated successfully.`,
      severity: 'success'
    };
    yield put(showAlertMessageAction(message));

    return e.data;
  },
  handleError: function*(e) {
    const message = {
      text: 'Profile data updating error',
      severity: 'error'
    };
    yield put(showAlertMessageAction(message));

    return e;
  }
};

const updateToken = {
  type: CONSTS.FETCH_ME,
  apiMethod: api.updateToken,
  handleSuccess: function*({ data }) {
    const message = {
      text: 'Update token success',
      severity: 'success'
    };
    yield put(showAlertMessageAction(message));
    const { payload } = yield select(meSelector)
    payload.auth_token = data.token
    return payload;
  },
  handleError: function*(e) {
    const message = {
      text: 'Update token error.',
      severity: 'error'
    };
    yield put(showAlertMessageAction(message));
    return e;
  }
}

const anyRequest = {
  type: CONSTS.FETCH_ME,
  apiMethod: api.fetchMeRequest,
  handleSuccess: function*({ data }) {
    const message = {
      text: 'Request send success',
      severity: 'success'
    };
    yield put(showAlertMessageAction(message));
    const { payload } = yield select(meSelector)
    payload.meta.request = data.kind
    return payload;
  },
  handleError: function*(e) {
    const message = {
      text: 'Send request error.',
      severity: 'error'
    };
    yield put(showAlertMessageAction(message));
    return e;
  }
}

const getUserGroups = {
  type: CONSTS.GROUPS_LIST,
  apiMethod: api.listUserGroups
};

export default function*() {
  yield* fetchSaga(fetchMeConfig);
  yield takeLatest(CONSTS.LOGIN, getFetchSaga(fetchLogin));
  yield takeLatest(CONSTS.LOGOUT, getFetchSaga(fetchLogout));
  yield takeLatest(CONSTS.LIST, getFetchSaga(getUsers));
  yield takeLatest(CONSTS.FETCH_CREATE, getFetchSaga(createUser));
  yield takeLatest(CONSTS.CURRENT, getFetchSaga(getUser));
  yield takeLatest(CONSTS.DELETE, getFetchSaga(deleteUser));
  yield takeLatest(CONSTS.UPDATE, getFetchSaga(updateUser));
  yield takeLatest(CONSTS.UPDATE_TOKEN, getFetchSaga(updateToken));
  yield takeLatest(CONSTS.SEND_REQUEST, getFetchSaga(anyRequest));
  yield takeLatest(CONSTS.FETCH_GROUPS, getFetchSaga(getUserGroups));
}

import { getFetchSaga, successSaga } from '../utils/fetch';
import { put, takeLatest } from 'redux-saga/effects';
import * as CONSTS from './consts';
import * as api from '../api';
import { getLocation } from '../../Routes';
import { history } from '../../commons';
import { showAlertMessageAction } from '../alerts/actions';
import { updateList } from './actions';
import { select, takeEvery } from '@redux-saga/core/effects';
import { findIndex } from '../../utils/tools';
import { backendsListSelector } from './selectors';
import { listSelector } from '../backendsForm/selectors';

const listBackends = {
  type: CONSTS.LIST,
  apiMethod: api.listBackends
};

const addItemToState = function*(_item) {
  const state = yield select(backendsListSelector);
  const formState = yield select(listSelector);
  let items = state.backendsList || [];
  const result = items.slice();
  const formId = _item.form;
  const item = { ..._item, form: formState.itemsById[formId] };
  result.push(item);
  yield successSaga(updateList([...result]));
};

const createBackend = {
  type: CONSTS.CURRENT,
  apiMethod: api.createBackend,

  handleSuccess: function*(e) {
    const item = e && e.data;
    const name = item && item.name;
    const message = {
      text: `Backend "${name}" created successfully .`,
      severity: 'info'
    };
    yield addItemToState(item);
    const loc = getLocation();
    history.push(loc.ListBackend());

    yield put(showAlertMessageAction(message));

    return e;
  },
  handleError: function*(e) {
    console.log(e.data);
    const message = {
      text: 'An error occurred while creating the backend, try again please.',
      severity: 'error'
    };
    yield put(showAlertMessageAction(message));

    return e;
  }
};

const deleteFromState = function*(itemId) {
  const state = yield select(backendsListSelector);
  let items = state.backendsList || [];
  const itemIndex = findIndex(items, { id: itemId });
  if (itemIndex !== -1) {
    items.splice(itemIndex, 1);
    yield successSaga(updateList([...items]));
  }
};

const deleteBackend = {
  type: CONSTS.FETCH_CURRENT,
  apiMethod: api.deleteBackend,
  handleSuccess: function*(r) {
    const message = {
      text: 'Item deleted successfully.',
      severity: 'info'
    };

    yield deleteFromState(r.data);

    yield put(showAlertMessageAction(message));
    return { data: null };
  }
};

const updateFromState = function*(item) {
  const state = yield select(backendsListSelector);
  const formState = yield select(listSelector);
  let items = state.backendsList || []; // # TODO backendList renamed to items. To fix.
  const itemIndex = findIndex(items, { id: item.id });
  if (itemIndex !== -1) {
    const result = items.slice();
    const formId = item.form;
    result[itemIndex] = { ...item, form: formState.itemsById[formId] };
    yield successSaga(updateList([...result]));
  }
};

const updateBackend = {
  type: CONSTS.FETCH_CURRENT,
  apiMethod: api.updateBackend,
  handleSuccess: function*(e) {
    const item = e && e.data;
    const name = item && item.name;
    const message = {
      text: `Backend "${name}" updated successfully .`,
      severity: 'info'
    };
    if (item) {
      yield updateFromState(item);
    }

    const loc = getLocation();
    history.push(loc.ListBackend());

    yield put(showAlertMessageAction(message));
    return { data: e.data };
  }
};

export default function*() {
  yield takeLatest(CONSTS.FETCH_LIST, getFetchSaga(listBackends));
  yield takeLatest(CONSTS.FETCH_CREATE, getFetchSaga(createBackend));
  yield takeEvery(CONSTS.DELETE, getFetchSaga(deleteBackend));
  yield takeLatest(CONSTS.UPDATE, getFetchSaga(updateBackend));
}

import * as CONSTS from './consts';

export const fetchDatasets = (page, pageSize) => ({
  type: CONSTS.FETCH_LIST,
  payload: { pageSize, page: page || 1 }
});

export const fetchCreateDatasetAction = (name, user, tensors, description) => {
  return {
    type: CONSTS.FETCH_CREATE,
    payload: { name, user, tensors, description }
  };
};

export const deleteDataset = id => ({
  type: CONSTS.DELETE,
  payload: { id }
});

export const updateDataset = (id, name, tensors, description) => {
  const data = { id, name, tensors, description };
  return {
    type: CONSTS.UPDATE,
    payload: { id, data }
  };
};

import React from 'react';
import {useTranslation} from 'react-i18next';
import {useStyles} from './styles';
import {ReactComponent as CircuitsIcon} from '../../../components/icons/circuits.svg';


const CircuitsTitle = () => {
  const classes = useStyles();
  const {t} = useTranslation();
  return (
    <div className={classes.topText}>
      <CircuitsIcon className={classes.topIcon}/>
      <span className={classes.topSpan}>{t('circuits')}</span>
    </div>
  );
}

export default CircuitsTitle;

export const DOMAIN = 'SERVERS';

export const CURRENT_NAME = 'CURRENT';
export const LIST_NAME = 'LIST';

export const FETCH_LIST = `${DOMAIN}@FETCH`;
export const FETCH_CURRENT = `${DOMAIN}@CURRENT`;

export const CURRENT = `${DOMAIN}@${CURRENT_NAME}`;
export const LIST = `${DOMAIN}@${LIST_NAME}`;
export const UPDATE = `${DOMAIN}@UPDATE`;
export const NO_EFFECT = `${DOMAIN}@NO_EFFECT`;

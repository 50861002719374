import React from 'react';
import {useStyles} from './styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';


const Tab = (props) => {
  const { className, value, label, startIcon: StartIcon, selected, onChange, disabled, selectedClass, ...rest } = props;
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.rootTab, className, selected && selectedClass, !selected && classes.rootNotSelected)}
      {...rest}
    >
      <Button
        className={clsx(classes.button, selected ? classes.selected : classes.notSelected)}
        color={selected ? 'default' : 'secondary'}
        disabled={disabled}
        onClick={() => onChange(value)}
        startIcon={StartIcon && <StartIcon className={classes.tabIcon}/>}
        variant="outlined"
      >
        {label}
      </Button>
    </div>
  );
}

Tab.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  selected: PropTypes.bool,
  selectedClass: PropTypes.string,
  startIcon: PropTypes.object,
  value: PropTypes.string,
};

export default Tab;

export const DOMAIN = 'NOTIFICATIONS';
export const CONNECT_ACTION = `${DOMAIN}@CONNECT`;
export const RECEIVE_ACTION = `${DOMAIN}@RECEIVE`;
export const SAVE_MESSAGES = `${DOMAIN}@MESSAGES`;
export const MESSAGES = `MESSAGES`;

export const TYPE = {
  LOG: 10,
  STATUS: 20,
  METRIC: 30,
  UTILIZATION: 40,
  RESULT: 50
};

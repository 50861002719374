import React from 'react';
import PropTypes from 'prop-types';
import {useStyles} from './styles';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {getMenuItem} from './utils';
import {getLocation} from '../../../Routes';
import {useHistory} from 'react-router';
import {ReactComponent as DashboardIcon} from '../../../components/icons/dashboard.svg';
import {ReactComponent as TasksIcon} from '../../../components/icons/tasks.svg';
import {ReactComponent as ChainsIcon} from '../../../components/icons/circuits.svg';
import {ReactComponent as NotebooksIcon} from '../../../components/icons/notebooks.svg';
import {ReactComponent as ServicesIcon} from '../../../components/icons/backends.svg';
import {ReactComponent as BenchmarksIcon} from '../../../components/icons/benchmarks.svg';
import {ReactComponent as DatasetsIcon} from '../../../components/icons/datasets.svg';
import {ReactComponent as UserIcon} from '../../../components/icons/account_manager.svg';
import {ReactComponent as ServersIcon} from '../../../components/icons/servers.svg';
import {ReactComponent as LogsIcon} from '../../../components/icons/log.svg';
import clsx from 'clsx';
import { meSelector } from "../../../core/users/selectors";
import { useSelector } from "react-redux";

const SidebarNav = props => {
  const { className, ...rest } = props;
  const { payload } = useSelector(meSelector)
  const {t} = useTranslation();
  const loc = getLocation();
  const classes = useStyles();
  const history = useHistory();

  const publicPages = [
    getMenuItem('Dashboard', t('dashboard'), props => (
      <DashboardIcon
        className={classes.iconItem}
        {...props}
      />
    ), loc, history, classes),
    getMenuItem('Tasks', t('tasks'), props => (
      <TasksIcon
        className={classes.iconItem}
        {...props}
      />
    ), loc, history, classes),
    getMenuItem('Circuits', t('circuits'), props => (
      <ChainsIcon
        className={classes.iconItem}
        {...props}
      />
    ), loc, history, classes),

    // Temporary disabled
    // getMenuItem('Notebooks', t('notebooks'), props => (
    //   <NotebooksIcon
    //     className={classes.iconItem}
    //     {...props}
    //   />
    // ), loc, history, classes),

    getMenuItem('Services', t('services'), props => (
      <ServicesIcon
        className={classes.iconItem}
        {...props}
      />
    ), loc, history, classes),

    // Temporary disabled
    // getMenuItem('Benchmarks', t('benchmarks'), props => (
    //   <BenchmarksIcon
    //     className={classes.iconItem}
    //     {...props}
    //   />
    // ), loc, history, classes),
    // getMenuItem('Datasets', t('datasets'), props => (
    //   <DatasetsIcon
    //     className={classes.iconItem}
    //     {...props}
    //   />
    // ), loc, history, classes),
  ];

  if (payload && payload.is_staff) {
    publicPages.push(
      getMenuItem('ServersList', t('Servers'), props => (
        <ServersIcon
          className={classes.iconItem}
          {...props}
        />
      ), loc, history, classes),
    )
    publicPages.push(
      getMenuItem('Management', t('Management'), props => (
        <UserIcon
          className={classes.iconItem}
          {...props}
        />
      ), loc, history, classes)
    )
    publicPages.push(
      getMenuItem('Logs', t('Logs'), props => (
        <LogsIcon
          className={classes.iconItem}
          {...props}
        />
      ), loc, history, classes)
    )
  }

  return (
    <List
      {...rest}
      className={clsx(classes.root, className)}
    >
      {publicPages.map(page => (
        <ListItem
          className={classes.item}
          disableGutters
          key={page.key}
          onClick={page.href}
        >
          <ListItemIcon className={classes.listItem}>{page.icon}</ListItemIcon>
          <ListItemText className={classes.listText}>{page.title}</ListItemText>
        </ListItem>
      ))}

    </List>
  );

};

SidebarNav.propTypes = {
  className: PropTypes.string
};

export default SidebarNav;

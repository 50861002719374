import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  appbar: {
    padding: theme.spacing(2, 5),
    boxShadow: 'none',
    top: 'auto',
    bottom: 0
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '12px',
    color: theme.palette.secondary.main
  },
  right: {
    display: 'flex'
  },
  rightItem: {
    padding: theme.spacing(0, 1),
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

const BottomBar = () => {
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <div className={classes.root}>
      <AppBar
        className={classes.appbar}
        position="absolute"
      >
        <Toolbar className={classes.toolbar}>
          <div>
            © Russian Quantum Center
          </div>
          <div className={classes.right}>
            <div className={classes.rightItem}>{t('termsOfUse')}</div>
            <div className={classes.rightItem}>{t('privacyPolicy')}</div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default BottomBar;

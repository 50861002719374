import React from 'react';
import {useTranslation} from 'react-i18next';
import {useStyles} from './styles';
import {ReactComponent as Icon} from '../../../components/icons/account_manager.svg';
import capitalize from 'lodash/capitalize'


const ManagementTitle = () => {
  const classes = useStyles();
  const {t} = useTranslation();
  return (
    <div className={classes.topText}>
      <Icon className={classes.topIcon}/>
      <span className={classes.topSpan}>{capitalize(t('user management'))}</span>
    </div>
  );
}

export default ManagementTitle;

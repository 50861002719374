import * as CONSTS from './consts';

export const fetchBackends = (page, pageSize) => ({
  type: CONSTS.FETCH_LIST,
  payload: { pageSize, page: page || 1 }
});

export const createAction = ({ id, name, desc, form }) => {
  return {
    type: CONSTS.FETCH_CREATE,
    payload: { id, name, desc, form }
  };
};

export const deleteAction = id => ({
  type: CONSTS.DELETE,
  payload: { id }
});

export const updateAction = ({ id, name, desc, form }) => {
  const data = { id, name, desc, form };
  return {
    type: CONSTS.UPDATE,
    payload: { id, data }
  };
};

export const updateList = items => ({
  type: CONSTS.LIST,
  payload: [...items]
});

import { combineReducers } from 'redux';
import DatasetsReducer from './datasets/reducers';
import UsersReducer from './users/reducers';
import AppReducer from './app/reducers';
import MatrixReducer from './matrix/reducers';
import TasksReducer from './tasks/reducers';
import ServersReducer from './servers/reducers';
import TemplatesReducer from './templates/reducers';
import alerts from './alerts/reduser';
import BackendsReducer from './backends/reducers';
import BackendsFormReducer from './backendsForm/reducers';
import formFieldsReducer from './formFields/reducers';
import fieldsTypesReducer from './fieldTypes/reducers';
import validatorsReducer from './validators/reducers';
import notificationsReducer from './notifications/reducers';
import emailActivationsReducers from './emailActivation/reducers'

export default combineReducers({
  ...UsersReducer,
  ...AppReducer,
  ...MatrixReducer,
  ...TasksReducer,
  ...ServersReducer,
  ...TemplatesReducer,
  alerts,
  ...DatasetsReducer,
  ...BackendsReducer,
  ...BackendsFormReducer,
  ...formFieldsReducer,
  ...fieldsTypesReducer,
  ...validatorsReducer,
  ...notificationsReducer,
  ...emailActivationsReducers
});

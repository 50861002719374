import React from 'react';
import clsx from 'clsx';
import styles from './index.module.css';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';

const BaseStatus = ({ title, children, className, fillClassName, fill }) => {
  return (
    <div className={clsx(styles.statusWrapper)}>
      <div
        className={clsx(
          styles.statusContainer,
          className,
          fill && fillClassName
        )}>
        <Tooltip title={title}>
          {children}
        </Tooltip>
      </div>
    </div>
  );
};

BaseStatus.propTypes = {
  children: PropTypes.object,
  className: PropTypes.string,
  fill: PropTypes.bool,
  fillClassName: PropTypes.string,
  title: PropTypes.string
}

export default BaseStatus;

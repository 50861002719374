import { TASK_STATUS } from '../../core/tasks/consts';
import {
  Canceled,
  Created,
  Error,
  InProgress,
  Pending,
  Success,
  Queue
} from '../status';
import React from 'react';

export const getStatuses = fill => ({
  [TASK_STATUS.CREATED]: <Created fill={fill} />,
  [TASK_STATUS.RUNNING]: <InProgress fill={fill} />,
  [TASK_STATUS.CANCELED]: <Canceled fill={fill} />,
  [TASK_STATUS.ERROR]: <Error fill={fill} />,
  [TASK_STATUS.SUCCESS]: <Success fill={fill} />,
  [TASK_STATUS.PENDING]: <Pending fill={fill} />,
  [TASK_STATUS.SENDING]: <Pending fill={fill} />,
  [TASK_STATUS.QUEUE]: <Queue fill={fill} />
});

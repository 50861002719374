import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {ReactComponent as Icon} from '../../../components/icons/help.svg';
import {ReactComponent as RefreshIcon} from '../../../components/icons/refresh.svg';
import {ReactComponent as CopyIcon} from '../../../components/icons/copy.svg';
import {FormControl, IconButton, InputAdornment, OutlinedInput, Tooltip} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {meSelector} from '../../../core/users/selectors';
import {useAction} from '../../../utils/hooks';
import {updateToken} from '../../../core/users/actions';
import PropTypes from 'prop-types';

const useStyles = makeStyles( theme => ({
  root: {
    width: '100%',
    borderRadius: 7
  },
  container: {
    padding: theme.spacing(0)
  },
  item: {
    padding: theme.spacing(1.5, 0),
    color: theme.palette.grey[700]
  },
  icon: {
    width: 20,
    fill: theme.palette.info.main,
    stroke: theme.palette.info.main,
    margin: theme.spacing(0, 0, 0, 2),
    strokeWidth: '0.2px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  header: {
    fontSize: 20,
    color: theme.palette.grey[600],
    padding: theme.spacing(1.5, 0, 1.5, 0)
  },
  formItem: {
    margin: theme.spacing(1, 0),
  },
  back: {
    backgroundColor: theme.palette.primary.contrastText
  },
  button: {
    marginRight:theme.spacing(1) + 'px!important'
  },
  buttonIcon: {
    width: 16,
    fill: theme.palette.info.main,
    stroke: theme.palette.info.main,
    strokeWidth: '0.2px'
  },
}))

const RecentTasks = (props) => {
  const { payload } = useSelector(meSelector)
  const classes = useStyles();
  const {t} = useTranslation();
  const updateToken_ = useAction(updateToken)

  const key = payload ? payload.auth_token : 'not_exists';
  const ref = useRef();

  const handleClickCopy = () => {
    navigator.clipboard.writeText(key);
  }

  return (
    <div
      className={classes.root}
      ref={props.r}
    >
      <div className={classes.container}>
        <div className={classes.header}>
          {t('API key')}
          <Tooltip title={'help text'}>
            <Icon className={classes.icon} />
          </Tooltip>
        </div>
        <div>
          <FormControl
            className={classes.formItem}
            fullWidth
            variant="outlined"
          >
            <OutlinedInput
              className={classes.back}
              endAdornment={
                <InputAdornment
                  className={classes.back}
                  position="end"
                >
                  <IconButton
                    className={classes.button}
                    edge="end"
                    onClick={updateToken_}
                    size={'small'}
                  >
                    <RefreshIcon className={classes.buttonIcon} />
                  </IconButton>
                  <IconButton
                    edge="end"
                    onClick={handleClickCopy}
                    onMouseDown={handleClickCopy}
                    size={'small'}
                  >
                    <CopyIcon className={classes.buttonIcon}/>
                  </IconButton>
                </InputAdornment>
              }
              inputRef={ref}
              margin="dense"
              type={'password'}

              value={key}

            />
          </FormControl>
        </div>


      </div>
    </div>
  );
}

RecentTasks.propTypes = {
  r: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })])
};

export default RecentTasks;

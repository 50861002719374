import React from 'react';
import {useStyles} from './styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';


const Tabs = (props) => {
  const { className, children, value, ...rest } = props;
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.start}/>
      {children.map(item => {
        return { ...item, props: { ...item.props, selected: item.props.value === value, ...rest}};
      })}
      <div className={classes.end}/>
    </div>
  );
}

Tabs.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  value: PropTypes.string.isRequired
};


export default Tabs;

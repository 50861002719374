import * as CONSTS from './consts';

export const uploadMatrix = (file, name) => ({
  type: CONSTS.UPLOAD,
  payload: { file, name }
});

export const fetchMatrix = page => ({
  type: CONSTS.FETCH,
  payload: { page }
});

export const deleteMatrix = id => ({
  type: CONSTS.DELETE,
  payload: { id }
});

export const bulkDeleteMatrix = data => ({
  type: CONSTS.BULK_DELETE,
  payload: { data }
});

export const updateMatrix = (id, name, description) => {
  const data = { id, name, description };

  return {
    type: CONSTS.UPDATE,
    payload: { id, data }
  };
};

export const updateList = items => ({
  type: CONSTS.LIST,
  payload: [...items]
});

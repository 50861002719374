import React from 'react';
import BaseStatus from './BaseStatus';
import styles from './index.module.css';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

const Success = ({ fill }) => {
  const {t} = useTranslation();
  return (
    <BaseStatus
      title={t('SUCCESS')}
      fill={!!fill}
      className={styles.success}
      fillClassName={styles.successFill}>
      <FiberManualRecordIcon />
    </BaseStatus>
  );
};

Success.propTypes = {
  fill: PropTypes.bool,
};

export default Success;

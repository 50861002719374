export const QUANTUM = 'quantum'
export const SIMULATOR = 'simulator'
export const CLASSIC = 'classic'

export const BackendsTypes = {
  [QUANTUM]: 1,
  [SIMULATOR]: 2,
  [CLASSIC]: 3,
}

export const MAX_SERVICES = 3

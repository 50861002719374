import React from 'react';
import BaseStatus from './BaseStatus';
import ErrorIcon from '@material-ui/icons/Error';
import styles from './index.module.css';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

const Error = ({ fill }) => {
  const {t} = useTranslation();
  return (
    <BaseStatus
      title={t('ERROR')}
      fill={!!fill}
      className={styles.error}
      fillClassName={styles.errorFill}>
      <ErrorIcon />
    </BaseStatus>
  );
};

Error.propTypes = {
  fill: PropTypes.bool,
};

export default Error;

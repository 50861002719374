import React from 'react';
import {useTranslation} from 'react-i18next';
import {useStyles} from './styles';
import {ReactComponent as Icon} from '../../../components/icons/user.svg';
import {useSelector} from 'react-redux';
import {meSelector} from '../../../core/users/selectors';



const ManagementTitle = () => {
  const classes = useStyles();
  const {t} = useTranslation();
  const { payload: user } = useSelector(meSelector);

  return (
    <div className={classes.topText}>
      <Icon className={classes.topIcon}/>
      <span className={classes.topSpan}>{t('Username')}: {user && user.username}</span>
    </div>
  );
}

export default ManagementTitle;

import { getFetchSaga } from '../utils/fetch';
import { put, takeLatest } from 'redux-saga/effects';
import * as CONSTS from './consts';
import * as api from '../api';
import { getLocation } from '../../Routes';
import { history } from '../../commons';
import { select } from '@redux-saga/core/effects';
import { taskListSelector } from './selectors';
import { showAlertMessageAction } from '../alerts/actions';

const createTask = {
  type: CONSTS.CURRENT,
  apiMethod: api.createTask,
  handleSuccess: function(data) {
    const loc = getLocation();
    history.push(loc.NewTasks());
    return data;
  }
};

const bulkTask = {
  type: CONSTS.BULK,
  apiMethod: api.bulkTasksCreate,
  handleSuccess: function*(data) {
    const message = {
      text: `Tasks "${!!data &&
        !!data.data &&
        data.data.length &&
        data.data.join(' ')}" created successfully .`,
      severity: 'info'
    };
    yield put(showAlertMessageAction(message));
    return data;
  }
};

const getTasks = {
  type: CONSTS.LIST,
  apiMethod: api.listTasks
};

const getTask = {
  type: CONSTS.CURRENT,
  apiMethod: api.fetchTask
};

const stopTask = {
  type: CONSTS.CURRENT,
  apiMethod: api.stopTask,
  handleSuccess: function*() {
    const state = yield select(taskListSelector);
    return state.payload;
  }
};

const getFilteredTasks = {
  type: CONSTS.FILTERED_LIST,
  apiMethod: api.filteredTasks,
  handleSuccess: function(data) {
    return data.data;
  }
};

const getAggregatedTasks = {
  type: CONSTS.AGGREGATED_LIST,
  apiMethod: api.getAggregatedTasks,
  handleSuccess: function(data) {
    return data.data;
  }
};

const getTVETasks = {
  type: CONSTS.TVE_LIST,
  apiMethod: api.timeVsEnergyTasks,
  handleSuccess: function(data) {
    return data.data;
  }
};

export default function*() {
  yield takeLatest(CONSTS.FETCH_CREATE, getFetchSaga(createTask));
  yield takeLatest(CONSTS.FETCH_BULK_CREATE, getFetchSaga(bulkTask));
  yield takeLatest(CONSTS.FETCH_LIST, getFetchSaga(getTasks));
  yield takeLatest(CONSTS.FETCH_CURRENT, getFetchSaga(getTask));
  yield takeLatest(CONSTS.FETCH_STOP, getFetchSaga(stopTask));
  yield takeLatest(CONSTS.FETCH_FILTERED, getFetchSaga(getFilteredTasks));
  yield takeLatest(CONSTS.FETCH_AGGREGATED, getFetchSaga(getAggregatedTasks));
  yield takeLatest(CONSTS.FETCH_TVE, getFetchSaga(getTVETasks));
}

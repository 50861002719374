import {getPagination, getServerId, getUserId} from '../../utils/hooks';
import {history} from '../../commons';

export const getSpecifyingParameters = () => {
  const userId = getUserId(history);
  const serverId = getServerId(history);
  const [page, pageSize] = getPagination(history);
  return [userId, serverId, page, pageSize];
}
export const isAddOrInsert = (statuses, status) => {
  return statuses.indexOf(status) !== -1;
}
const isTheListIsIncompleteOrCompleteOnTheFirstPage = (page, pageSize, count) => {
  return (count <= pageSize) && (page === 1)
}
//must be between items in the full list on not the first page
const isMustBeBetween = (items, pageSize, taskId) => {
  if (items.length > 1) {
    const firstId = items[0].id;
    const lastId = items[pageSize - 1].id;
    if ((firstId > taskId) && (lastId < taskId)) {
      return true;
    }
  }
  return false;
}
const isNeedToPast = (isInList, taskId, pageSize, page, items) => {
  if (!isInList) {
    if (isTheListIsIncompleteOrCompleteOnTheFirstPage(page, pageSize, items.length)) return true;
    if (isMustBeBetween(items, pageSize, taskId)) return true;
  }
  return false;
}
const isBelowTheList = (isInList, page, pageSize, count, items, taskId) => {
  return !isInList &&
    (page !== 1) &&
    (count === pageSize) &&
    (items.length > 0 && (taskId < (items[0].id)))

}
const isIncompleteOrCompleteListOnTheFirstPage = (isInList, page, pageSize, count) => {
  return isInList && (page === 1) && (count <= pageSize)
}
export const isNeedToUpdate = (
  page, pageSize, count, statuses, status, taskId, isAddOrPast, items,
  taskIndex, isInList) => {

  if (isAddOrPast) {
    // if need to add to the list
    if (isInList) {
      return false
    } else {
      // if need to insert into the list
      if (isNeedToPast(isInList, taskId, pageSize, page, items)) return false;
    }
  } else {
    // if need to remove from the list
    if (isIncompleteOrCompleteListOnTheFirstPage(isInList, page, pageSize, items.length)) return false;
    if (isBelowTheList(isInList, page, pageSize, count, items, taskId)) return false;
  }
  return true;
};
export const isEmptyList = (items) => {
  return items.length === 0
}
export const isTaskIdBelowAllElements = (items, taskId) => {
  if (!isEmptyList(items)) {
    return taskId < items[items.length - 1].id
  }
  return false
}
export const isTheTaskIdIsAboveAllElements = (items, taskId) => {
  if (!isEmptyList(items)) {
    return taskId > items[0].id;
  }
  return false
}
export const searchIndexToInsert = (items, taskId) => {
  let searchIndex = 0;
  for (let i = 0; i <= items.length - 1; i++) {
    const item = items[i];
    if (item.id > taskId) {
      searchIndex = i;
      break;
    }
  }
  return searchIndex;
}
export const isListedOnTheFirstPage = (isInList, page, pageSize, count) => {
  return isInList && (page === 1) && (count <= pageSize)
}

export const isIgnore = (status, statuses) => {
  return statuses.indexOf(status) !== -1;
}

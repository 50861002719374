import axios from 'axios';
import { apiPrefix, authApiPrefix } from '../../const';

const api = axios.create({
  baseURL: '',
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken'
});

export const fetchMe = () => api.get(`${apiPrefix}/me/`);
export const fetchMeRequest = ({ kind }) => api.post(`${apiPrefix}/me/request/`, { kind });
export const fetchSupport = data => api.post(`${apiPrefix}/me/support/`, data);

export const login = ({ username, password }) =>
  api.post('/api/auth/login/', {
    username,
    password
  });

export const logout = () => api.post('/api/auth/logout/', {});

export const signUp = (payload) => api.post('/api/auth/signup/', payload);

export const listMatrix = ({ page }) =>
  api.get(`${apiPrefix}/tensors/`, { params: { page } });
export const deleteMatrix = ({ id }) =>
  api.delete(`${apiPrefix}/tensors/${id}/`);

export const bulkDeleteMatrix = ({ data }) =>
  api.post(`${apiPrefix}/tensors/bulk/`, data);

export const updateMatrix = ({ id, data }) =>
  api.patch(`${apiPrefix}/tensors/${id}/`, data);

export const uploadMatrix = ({ file, name }) => {
  const form = new FormData();
  form.append('file', file);
  form.append('name', name);
  return api.post(`${apiPrefix}/tensors/`, form, {
    headers: { 'content-type': 'multipart/form-data' }
  });
};

export const listTasks = ({
  page,
  pageSize,
  userId,
  serverId,
  statusFilter,
  backendFilter
}) => {
  const params = { page };
  if (pageSize !== undefined) params.pageSize = pageSize;
  if (userId !== undefined) params.userId = userId;
  if (serverId !== undefined) params.serverId = serverId;
  if (statusFilter !== undefined) params.status = statusFilter;
  if (backendFilter !== undefined) params.backend = backendFilter;
  return api.get(`${apiPrefix}/tasks/`, { params });
};

export const listUsers = ({ page, pageSize }) => {
  const params = { page };
  if (pageSize !== undefined) params.pageSize = pageSize;
  return api.get(`${apiPrefix}/users/`, { params });
};

export const createUser = payload => {
  return api.post(`${apiPrefix}/users/`, payload);
};

export const listUserGroups = payload => {
  return api.get(`${apiPrefix}/user-groups/`, payload);
};

export const createTask = payload => api.post(`${apiPrefix}/tasks/`, payload);
export const bulkTasksCreate = payload =>
  api.post(`${apiPrefix}/tasks/bulk/`, payload);
export const fetchTask = ({ id }) => api.get(`${apiPrefix}/tasks/${id}/`);
export const stopTask = ({ id }) => api.put(`${apiPrefix}/tasks/${id}/stop/`);

export const fetchUser = ({ id }) => api.get(`${apiPrefix}/users/${id}/`);
export const deleteUser = ({ id }) => api.delete(`${apiPrefix}/users/${id}/`);
export const updateUser = ({ id, data }) =>
  api.patch(`${apiPrefix}/users/${id}/`, data);

export const filteredTasks = ({ datasets, templates }) =>
  api.get(`${apiPrefix}/tasks/time-to-solution/`, {
    params: { datasets, templates }
  });

export const getAggregatedTasks = ({ datasets, backends, metric }) =>
  api.get(`${apiPrefix}/tasks/aggregated/`, {
    params: { datasets, backends, metric }
  });

export const timeVsEnergyTasks = ({ datasets, templates }) =>
  api.get(`${apiPrefix}/tasks/time-vs-energy/`, {
    params: { datasets, templates }
  });

export const fetchServer = ({ id }) => api.get(`${apiPrefix}/servers/${id}/`);
export const listServers = ({ page, pageSize }) => {
  const params = { page };
  if (pageSize !== undefined) params.pageSize = pageSize;
  return api.get(`${apiPrefix}/servers/`, { params });
};
export const updateServer = ({ id, data }) =>
  api.patch(`${apiPrefix}/servers/${id}/`, data);

export const listTemplates = ({ backendId }) => {
  const params = { backendId };
  return api.get(`${apiPrefix}/templates/`, { params });
};

export const listAllTemplates = ({ page, pageSize }) => {
  return api.get(`${apiPrefix}/templates/`, { params: { page, pageSize } });
};

export const createTemplate = payload =>
  api.post(`${apiPrefix}/templates/`, payload);

export const updateTemplate = ({ id, data }) =>
  api.patch(`${apiPrefix}/templates/${id}/`, data);

export const deleteTemplate = ({ templateId }) =>
  api.delete(`${apiPrefix}/templates/${templateId}/`);

export const listDatasets = ({ page, pageSize }) => {
  const params = {};
  if (page !== undefined) params.page = page;
  if (pageSize !== undefined) params.pageSize = pageSize;
  return api.get(`${apiPrefix}/datasets/`, { params });
};
export const createDataset = payload =>
  api.post(`${apiPrefix}/datasets/`, payload);
export const deleteDataset = ({ id }) =>
  api.delete(`${apiPrefix}/datasets/${id}/`);
export const updateDataset = ({ id, data }) =>
  api.patch(`${apiPrefix}/datasets/${id}/`, data);

export const listBackends = () => {
  return api.get(`${apiPrefix}/backends/`);
};
export const createBackend = payload =>
  api.post(`${apiPrefix}/backends/`, payload);
export const deleteBackend = ({ id }) =>
  api.delete(`${apiPrefix}/backends/${id}/`);
export const updateBackend = ({ id, data }) =>
  api.patch(`${apiPrefix}/backends/${id}/`, data);

export const listBackendForms = () => {
  return api.get(`${apiPrefix}/backend-forms/`);
};
export const createBackendForms = payload =>
  api.post(`${apiPrefix}/backend-forms/`, payload);
export const deleteBackendForms = ({ id }) =>
  api.delete(`${apiPrefix}/backend-forms/${id}/`);
export const updateBackendForms = ({ id, data }) =>
  api.patch(`${apiPrefix}/backend-forms/${id}/`, data);

export const listFormFields = () => {
  return api.get(`${apiPrefix}/form-fields/`);
};
export const createFormFields = payload =>
  api.post(`${apiPrefix}/form-fields/`, payload);
export const deleteFormFields = ({ id }) =>
  api.delete(`${apiPrefix}/form-fields/${id}/`);
export const updateFormFields = ({ id, data }) =>
  api.patch(`${apiPrefix}/form-fields/${id}/`, data);

export const listFieldItems = () => {
  return api.get(`${apiPrefix}/field-items/`);
};
export const createFieldItems = payload =>
  api.post(`${apiPrefix}/field-items/`, payload);
export const deleteFieldItems = ({ id }) =>
  api.delete(`${apiPrefix}/field-items/${id}/`);
export const updateFieldItems = ({ id, data }) =>
  api.patch(`${apiPrefix}/field-items/${id}/`, data);

export const listFieldTypes = () => {
  return api.get(`${apiPrefix}/field-types/`);
};
export const createFieldTypes = payload =>
  api.post(`${apiPrefix}/field-types/`, payload);
export const deleteFieldTypes = ({ id }) =>
  api.delete(`${apiPrefix}/field-types/${id}/`);
export const updateFieldTypes = ({ id, data }) =>
  api.patch(`${apiPrefix}/field-types/${id}/`, data);

export const listValidators = () => {
  return api.get(`${apiPrefix}/validators/`);
};

export const listCircuits = () => {
  return api.get(`${apiPrefix}/circuits/?format=json`);
};

export const createCircuits = payload => {
  return api.post(`${apiPrefix}/circuits/?format=json`, payload);
};

export const getCircuit = id => api.get(`${apiPrefix}/circuits/${id}/?format=json`)

export const deleteCircuit = id => api.delete(`${apiPrefix}/circuits/${id}/`)

export const changeNameCircuit = (name, id) => {
  return api.patch(`${apiPrefix}/circuits/${id}/?format=json`, {name});
};

export const changeCircuitQASM = (qasm, id) => {
  return api.patch(`${apiPrefix}/circuits/${id}/?format=json`, {qasm});
};

export const getCircuitQASM = (id) => {
  return api.get(`${apiPrefix}/circuits/${id}/?format=qasm`);
};

export const sendEmail = payload=> {
  return api.post(`${apiPrefix}/contact/`, payload);
}

export const updateToken = () => {
  return api.post(`${apiPrefix}/token/`, {});
}

export const getNews = () => {
  return api.get(`${apiPrefix}/news/`);
};

export const createNews = ({ title, dt }) => {
  return api.post(`${apiPrefix}/news/`, { title, dt });
};

export const activateEmail = payload => {
  return api.post(`${authApiPrefix}/activation/`, payload);
}
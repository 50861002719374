export const DOMAIN = 'USERS';

// Reducers
export const ME_NAME = 'ME';
export const CURRENT_NAME = 'CURRENT';
export const LIST_NAME = 'LIST';
export const LIST_GROUPS = 'GROUPS_LIST';

// Actions
export const FETCH_LIST = `${DOMAIN}@FETCH_LIST`;
export const FETCH_CURRENT = `${DOMAIN}@FETCH_CURRENT`;
export const FETCH_ME = `${DOMAIN}@FETCH_ME`;
export const FETCH_CREATE = `${DOMAIN}@FETCH_CREATE`;
export const FETCH_GROUPS = `${DOMAIN}@FETCH_GROUPS`;

// Actions types
export const LOGIN = `${DOMAIN}@LOGIN`;
export const LOGOUT = `${DOMAIN}@LOGOUT`;
export const CURRENT = `${DOMAIN}@${CURRENT_NAME}`;
export const LIST = `${DOMAIN}@${LIST_NAME}`;
export const DELETE = `${DOMAIN}@DELETE`;
export const UPDATE = `${DOMAIN}@UPDATE`;
export const UPDATE_TOKEN = `${DOMAIN}@UPDATE_TOKEN`;
export const SEND_REQUEST = `${DOMAIN}@SEND_REQUEST`;
export const GROUPS_LIST = `${DOMAIN}@GROUPS_LIST`;

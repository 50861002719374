import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import Tabs from '../../../components/Tabs/Tabs';
import Tab from '../../../components/Tabs/Tab';
import grey from '@material-ui/core/colors/grey';
import {getLocation} from '../../../Routes';
import {ReactComponent as NewIcon} from '../../../components/icons/new.svg';
import {ReactComponent as RunningIcon} from '../../../components/icons/running.svg';
import {ReactComponent as DoneIcon} from '../../../components/icons/done.svg';
import {useHistory} from 'react-router';
import {Table, TableBody} from '@material-ui/core';
import TaskRow from './TaskRow';
import {useAction} from '../../../utils/hooks';
import {fetchTasks} from '../../../core/tasks/actions';
import {COMPLETED_TASKS, NEW_TASKS, RUNNING_TASKS} from '../../../core/tasks/consts';
import {useSelector} from 'react-redux';
// eslint-disable-next-line
import {taskListSelector, taskSelector} from "../../../core/tasks/selectors";

const TABS = {
  New: 'New',
  InProgress: 'InProgress',
  Finish: 'Finish',
}

const STATUSES = {
  [TABS.New]: NEW_TASKS,
  [TABS.InProgress]: RUNNING_TASKS,
  [TABS.Finish]: COMPLETED_TASKS
}

const useStyles = makeStyles( theme => ({
  root: {
    width: '100%',
    border: '2px solid ' + theme.palette.grey[300],
    borderRadius: 7,
    minHeight: 270
  },
  container: {
    padding: theme.spacing(1.5, 3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  item: {
    padding: theme.spacing(1.5, 0),
    color: theme.palette.grey[700]
  },
  icon: {
    width: 16,
    fill: theme.palette.info.main,
    stroke: theme.palette.info.main,
    margin: theme.spacing(0, 1, 1, 1),
    strokeWidth: '0.2px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  header: {
    fontSize: 22,
    color: theme.palette.grey[600],
    padding: theme.spacing(1.5, 0, 1.5, 0),
    marginBottom: theme.spacing(2.5),
  },
  tabs: {
    marginTop: -theme.spacing(2),
    '& .Mui-selected': {
      borderTop: `2px solid ${grey[300]}`,
      borderRight: `2px solid ${grey[300]}`,
      borderLeft: `2px solid ${grey[300]}`
    },
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.primary.contrastText
    },
    position: 'relative'
  },
  button: {
    zIndex: theme.zIndex.drawer + 2,
  },
  table: {

  },
  wrapper: {
    height: '300px',
    overflowY: 'auto'
  },
  taskName: {
    color: theme.palette.secondary.main
  },
  statusContainer: {
    paddingLeft: theme.spacing(3),
    display: 'flex',
    alignItems: 'center'
  },
  row: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgb(237,247,252)!important'
    },
    '& > td': {
      borderBottom: 'none'
    }
  },
  bottomContainer: {
    padding: theme.spacing(1.5, 3),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  bottomButton: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.secondary.main,
    textTransform: 'inherit',
    boxShadow: 'inherit',
    fontSize: 16,
    transition: theme.transitions.create('color', {
      duration: theme.transitions.duration.enteringScreen
    }),
    '&:hover': {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.info.main,
      boxShadow: 'inherit',
    }
  }
}))

const RecentTasks = (props) => {
  const classes = useStyles();
  const [tab, setTab] = useState(TABS.Finish)
  const { t } = useTranslation();
  const loc = getLocation();
  const history = useHistory();
  const fetchList = useAction(fetchTasks)
  const { items: tasks, isFetched } = useSelector(taskListSelector)

  const tabs = [
    { value: TABS.New, label: t('new'), startIcon: NewIcon },
    { value: TABS.InProgress, label: t('inProgress'), startIcon: RunningIcon },
    { value: TABS.Finish, label: t('completed'), startIcon: DoneIcon }
  ];

  const handleChange = (value) => {
    setTab(value)
  };

  useEffect(() => {
    fetchList(1, undefined, undefined, undefined, STATUSES[tab])
    // eslint-disable-next-line
  }, [tab])

  useEffect(() => {
    if (isFetched) props.onLoad()
  }, [isFetched]);

  return (
    <div
      className={classes.root}
      ref={props.r}
    >
      <div>
        <div className={classes.container}>
          <div className={classes.header}>
            {t('Recent tasks')}
          </div>
          <Button
            className={classes.button}
            color="primary"
            onClick={()=>{history.push(loc.NewTask())}}
            startIcon={<ControlPointIcon />}
            variant="contained"
          >
            {t('newTask')}
          </Button>
        </div>

        <Tabs
          className={classes.tabs}
          onChange={handleChange}
          value={tab}
        >
          {tabs.map(tab => (
            <Tab
              key={tab.value}
              label={tab.label}
              startIcon={tab.startIcon}
              value={tab.value}
            />
          ))}
        </Tabs>
        <div className={classes.wrapper}>
          <Table className={classes.table}>
            <TableBody>
              {(tasks || []).map(task => (
                <TaskRow
                  classes={classes}
                  key={`ti-${task.id}`}
                  {...task}
                />
              ))}
            </TableBody>
          </Table>
        </div>
        <div className={classes.bottomContainer}>
          <Button
            className={classes.bottomButton}
            onClick={()=>{history.push(loc.Tasks())}}
          >
            {t('All tasks')}
          </Button>
        </div>

      </div>
    </div>
  );
}

RecentTasks.propTypes = {
  onLoad: PropTypes.func,
  r: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })])
};

export default RecentTasks;
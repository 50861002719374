import {getFetchSaga, successSaga} from '../utils/fetch';
import { put, takeLatest, select } from 'redux-saga/effects';
import * as CONSTS from './consts';
import * as api from '../api';
import { showAlertMessageAction } from "../alerts/actions";
import { serverListSelector} from "./selectors";
import findIndex from 'lodash/findIndex'

const getServer = {
  type: CONSTS.CURRENT,
  apiMethod: api.fetchServer
};

const listServers = {
  type: CONSTS.LIST,
  apiMethod: api.listServers
};

const updateServer = {
  type: CONSTS.NO_EFFECT,
  apiMethod: api.updateServer,
  handleSuccess: function*({ data }) {
    const message = {
      text: `Server update successfully.`,
      severity: 'success'
    };
    const { items, pageSize, count } = yield select(serverListSelector)
    const index = findIndex(items, { id: data.id })
    items[index] = data
    yield successSaga({
      type: CONSTS.LIST,
      payload: { results: items, pageSize, count }
    })
    yield put(showAlertMessageAction(message));
    return { data: null };
  }
};

export default function*() {
  yield takeLatest(CONSTS.FETCH_LIST, getFetchSaga(listServers));
  yield takeLatest(CONSTS.FETCH_CURRENT, getFetchSaga(getServer));
  yield takeLatest(CONSTS.UPDATE, getFetchSaga(updateServer));
}

import {makeStyles} from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

export const useStyles = makeStyles((theme) => {
  const borderWidth = '2px';
  const borderColor = grey[300];
  return {
    root: {
      display: 'inline-flex',
      width: '100%'
    },
    rootTab: {
      '& .MuiButton-root:hover ': {
        background: 'none'
      }
    },
    rootNotSelected: {
      '& .MuiButton-root:hover ': {
        background: grey[50],
        borderTop: `${borderWidth} solid ${theme.palette.secondary.contrastText}`,
        borderLeft: `0px solid ${theme.palette.secondary.contrastText}`,
        borderRight: `0px solid ${theme.palette.secondary.contrastText}`,
        borderBottom: `${borderWidth} solid ${borderColor}`,
        boxShadow: '0 -1px 1px -1px rgba(0, 0, 0, .5)',
        cursor: 'point!important'
      }
    },
    tabIcon: {
      width: '18px',
      stroke: theme.palette.info.main,
      fill: theme.palette.info.main,
      strokeWidth: '0.2px'
    },
    button: {
      backgroundColor: theme.palette.secondary.contrastText,
      boxShadow: 'inherit',
      borderRadius: theme.spacing(0.6, 0.6, 0, 0),
      padding: theme.spacing(1.5, 4),
      whiteSpace: 'nowrap'
    },
    selected: {
      borderTop: `${borderWidth} solid ${borderColor}`,
      borderLeft: `${borderWidth} solid ${borderColor}`,
      borderRight: `${borderWidth} solid ${borderColor}`,
      borderBottom: `${borderWidth} solid ${theme.palette.secondary.contrastText}`,
      color: theme.palette.info.main,
      cursor: 'inherit'
    },
    notSelected: {
      borderTop: `${borderWidth} solid ${theme.palette.secondary.contrastText}`,
      borderLeft: `0px solid ${theme.palette.secondary.contrastText}`,
      borderRight: `0px solid ${theme.palette.secondary.contrastText}`,
      borderBottom: `${borderWidth} solid ${borderColor}`,
    },
    start: {
      borderBottom: `${borderWidth} solid ${borderColor}`,
      width: theme.spacing(2)
    },
    end: {
      borderBottom: `${borderWidth} solid ${borderColor}`,
      width: '100%'
    }
  }
});

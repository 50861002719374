import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {ReactComponent as UserIcon} from './icons/user.svg';
import {useTranslation} from 'react-i18next';
import {getLocation} from '../Routes';
import {useHistory} from 'react-router';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    color: theme.palette.secondary.contrastText,
    border: `2px solid ${theme.palette.secondary.contrastText}`,
    textTransform: 'inherit',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    margin: `0 ${theme.spacing(1)}px`,
    minWidth: '203px'
  },
  icon: {
    marginRight: `${theme.spacing(1)}px`,
    width: 18,
    strokeWidth: '0.2px',
    stroke: theme.palette.secondary.main,
    fill: theme.palette.secondary.main,
  }
}));


const SignUpButton = () => {
  const classes = useStyles();
  const {t} = useTranslation();

  const loc = getLocation();
  const history = useHistory();

  const signUpClick = (e) => {
    e.stopPropagation();
    history.push(loc.SignUp());
  };

  return (
    <div className={classes.root}>
      <Button
        className={classes.button}
        onClick={signUpClick}
      >
        <UserIcon className={classes.icon} />
        {t('signUp')}
      </Button>
    </div>
  );
};

export default SignUpButton;

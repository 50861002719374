import {Typography} from '@material-ui/core';
import React from 'react';

export const getMenuItem = (name, title, icon, loc, history, classes) => {
  const current = loc[`is${name}`]()
  const other = current ? {} : {
    href: () => {
      history.push(loc[name]());
    }
  }
  const color = current ? 'secondary' : 'primary';
  return {
    title: (
      <Typography
        classes={{ colorPrimary: classes.menuItem }}
        color={color}
      >
        {title}
      </Typography>
    ),
    icon: icon(),
    key: title,
    ...other
  };
};

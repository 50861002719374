import fromPairs from 'lodash/fromPairs';
import {TASK_STATUS} from '../core/tasks/consts';
import moment from 'moment';
import DateCell from '../views/TaskManager/components/DateCell';
import React from 'react';

export { default as get } from 'lodash/get';
export { default as isUndefined } from 'lodash/isUndefined';
export { default as isNaN } from 'lodash/isNaN';
export { default as findIndex } from 'lodash/findIndex';
export { default as remove } from 'lodash/remove';
export { default as fromPairs } from 'lodash/fromPairs';
export { default as isNumber } from 'lodash/isNumber';
export { default as minBy } from 'lodash/minBy';
export { default as intersection } from 'lodash/intersection';


export const Password = {

  _pattern : /[a-zA-Z0-9_\-+.]/,


  _getRandomByte : function()
  {
    // http://caniuse.com/#feat=getrandomvalues
    if(window.crypto && window.crypto.getRandomValues)
    {
      let result = new Uint8Array(1);
      window.crypto.getRandomValues(result);
      return result[0];
    }
    else if(window.msCrypto && window.msCrypto.getRandomValues)
    {
      let result = new Uint8Array(1);
      window.msCrypto.getRandomValues(result);
      return result[0];
    }
    else
    {
      return Math.floor(Math.random() * 256);
    }
  },

  generate : function(length)
  {
    return Array.apply(null, {'length': length})
      .map(function()
      {
        var result;
        while(true)
        {
          result = String.fromCharCode(this._getRandomByte());
          if(this._pattern.test(result))
          {
            return result;
          }
        }
      }, this)
      .join('');
  }

};

export const objFilter = (obj, filter) => {
  return fromPairs(
    Object.keys(obj)
      .filter(i => filter(obj[i]))
      .map(i => [i, obj[i]])
  );
};

export function getStyles(val, selected, theme, multiple) {
  if (multiple) {
    return {
      fontWeight:
        selected.indexOf(val) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium
    };
  } else {
    return {
      fontWeight:
        selected === val
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium
    };
  }
}

export const getStatusCell = (metrics, created, status, t, end) => {
  let dateCell;
  let dt;
  if (TASK_STATUS.SUCCESS === status || TASK_STATUS.CANCELED === status) {
    if (end) {
      dt = moment(end);
      dateCell = <DateCell
        at={t('at')}
        dt={dt}
        text={t('completed')}
      />
    } else {
      dt = moment(created);
      dateCell = <DateCell
        at={t('at')}
        dt={dt}
        text={t('created')}
      />
    }
  } else {
    dt = moment(created);
    dateCell = <DateCell
      at={t('at')}
      dt={dt}
      text={t('created')}
    />
  }
  return {dt, dateCell}
}

import usersSaga from './users/sagas';
import matrixSaga from './matrix/sagas';
import tasksSaga from './tasks/sagas';
import notificationsSaga from './notifications/sagas';
import serversSaga from './servers/sagas';
import templatesSaga from './templates/sagas';
import datasetsSaga from './datasets/sagas';
import backendsSaga from './backends/sagas';
import backendsFormSaga from './backendsForm/sagas';
import formFieldsSaga from './formFields/sagas';
import fieldTypesSaga from './fieldTypes/sagas';
import validatorsSaga from './validators/sagas';
import emailActivationSaga from './emailActivation/sagas';

export default [
  usersSaga,
  matrixSaga,
  tasksSaga,
  notificationsSaga,
  serversSaga,
  templatesSaga,
  datasetsSaga,
  backendsSaga,
  backendsFormSaga,
  formFieldsSaga,
  fieldTypesSaga,
  validatorsSaga,
  emailActivationSaga
];

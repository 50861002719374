import * as CONSTS from './consts';

export const fetchServers = (page, pageSize) => ({
  type: CONSTS.FETCH_LIST,
  payload: { pageSize, page: page || 1 }
});

export const fetchServer = id => ({
  type: CONSTS.FETCH_CURRENT,
  payload: { id }
});

export const updateServer = (id, backends, enable) => {
  const data = { backends, enable };
  return {
    type: CONSTS.UPDATE,
    payload: { id, data }
  };
};

export const updateUtilization = (payload, utilization) => {
  return {
    type: CONSTS.CURRENT,
    payload: { ...payload, utilization }
  };
};

export const DOMAIN = 'ACTIVATION';

// Reducers
export const EMAIL_ACTIVATION_REDUCER = 'EMAIL_ACTIVATION';

// Actions
export const ACTIVATE_EMAIL = `${DOMAIN}@ACTIVATE_EMAIL`;

// Actions types
export const EMAIL_ACTIVATION = `${DOMAIN}@EMAIL_ACTIVATION`;

import * as CONSTS from './consts';
import { getDomainSelector } from '../utils/fetch';
import { fromPairs } from '../../utils/tools';

const listPath = [CONSTS.DOMAIN, CONSTS.LIST_NAME];

const _listSelector = getDomainSelector(listPath, null);

export const backendsListSelector = state => {
  const { payload, isFetching, isFetched } = _listSelector(state);
  return {
    isFetching,
    isFetched,
    items: payload ? payload : [],
    itemsById: payload ? fromPairs(payload.map(i => [i.id, i])) : []
  };
};

export const metricTypesSelector = () => {
  const interruptionTypes = [
    { id: 1, name: 'time' },
    { id: 2, name: 'energy' }
  ];
  return {
    items: interruptionTypes,
    itemsById: fromPairs(interruptionTypes.map(i => [i.id, i]))
  };
};

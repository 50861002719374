export const DOMAIN = 'TEMPLATES';

// Reducers
export const LIST_NAME = 'LIST';
export const ALL_LIST_NAME = 'ALL_LIST';

// Actions
export const FETCH_LIST = `${DOMAIN}@FETCH_LIST`;
export const FETCH_CREATE = `${DOMAIN}@FETCH_CREATE`;
export const FETCH_CREATE_PUSHED = `${DOMAIN}@FETCH_CREATE_PUSHED`;
export const FETCH_DELETE = `${DOMAIN}@FETCH_DELETE`;
export const FETCH_ALL_LIST = `${DOMAIN}@FETCH_ALL_LIST`;
export const FETCH_UPDATE = `${DOMAIN}@FETCH_UPDATE`;

// Actions types
export const LIST = `${DOMAIN}@${LIST_NAME}`;
export const ALL_LIST = `${DOMAIN}@${ALL_LIST_NAME}`;

import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  topIcon: {
    width: 22,
    fill: theme.palette.info.main,
    stroke: theme.palette.info.main,
    margin: theme.spacing(0, 1),
    strokeWidth: '0.2px'
  },
  topText: {
    display: 'flex',
    alignItems: 'center'
  },
  topSpan: {
    margin: theme.spacing(0, 1),
    color: theme.palette.info.main,
    fontWeight: 500,
    fontSize: 22
  }
}));

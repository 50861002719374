import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import {getNews} from '../../../core/api';
import moment from 'moment';
import Link from '@material-ui/core/Link';
import capitalize from 'lodash/capitalize';
import {useSelector} from 'react-redux';
import {meSelector} from '../../../core/users/selectors';
import NewNewsModal from './NewNewsModal';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';

const useStyles = makeStyles( theme => ({
  root: {},
  newsRoot: {
    width: '100%',
    border: '2px solid ' + theme.palette.grey[300],
    borderRadius: 7
  },
  container: {
    padding: theme.spacing(1.5, 3)
  },
  item: {
    padding: theme.spacing(1.5, 0),
    color: theme.palette.grey[700]
  },
  icon: {
    width: 16,
    fill: theme.palette.info.main,
    stroke: theme.palette.info.main,
    margin: theme.spacing(0, 1, 1, 1),
    strokeWidth: '0.2px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  header: {
    fontSize: 22,
    color: theme.palette.grey[600],
    padding: theme.spacing(1.5, 0)
  },
  wrapper: {
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  news: {
    paddingTop: theme.spacing(1.5)
  },
  date: {
    '& > span': {
      fontSize: 24,
    },
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.info.main,
  },
  text: {
    marginTop: theme.spacing(0.5),
    fontSize: 14,
    color: theme.palette.grey[800],
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box'
  },
  button: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.secondary.main,
    textTransform: 'inherit',
    boxShadow: 'inherit',
    fontSize: 16,
    transition: theme.transitions.create('color', {
      duration: theme.transitions.duration.enteringScreen
    }),
    '&:hover': {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.info.main,
      boxShadow: 'inherit',
    }
  },
  bottomContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }

}))

const News = ({heights, onLoad}) => {
  const [news, setNews] = useState([])
  const [totalHeight, setTotalHeight] = useState(0);
  const classes = useStyles();
  const {t} = useTranslation();
  const {payload: user} = useSelector(meSelector);
  const isAdmin = !!user && user.is_staff;

  useEffect(() => {
    getNews()
    // .then(r => r.json())
      .then(r => r.data)
      .then(({ results }) => setNews(results))
      .catch(() => {console.log('Error when fetch news')})
      .then(() => onLoad && onLoad())
  }, [])

  const border = 2;
  const padding = 16;
  useEffect(() => {
    let p = 40;
    if (isAdmin) p = 0;
    setTotalHeight(
      (heights.recent + heights.computing + (border * 4) +
      (padding * 2)) - (heights.api + 24 + 158) + p);
  }, [heights.recent, heights.computing, heights.api, isAdmin]);

  return (<div className={classes.root}>
    {isAdmin && <NewNewsModal onAdd={data => {setNews([data, ...news])}}/>}
    <div className={classes.newsRoot}>
      <div className={classes.container}>
        <div className={classes.header}>
          {t('News')}
        </div>
        <div
          className={clsx(classes.wrapper)}
          style={{ height: totalHeight ? totalHeight : 0}}
        >
          {news.map(item => <div
            className={classes.news}
            key={item.id}
          >
            <div className={classes.date}>
              <span>{moment(item.dt).format('DD.MM')}</span>
              {item.link && <Link
                color="secondary"
                href={item.link}
              >{capitalize(t('link'))}</Link>}
            </div>
            <Truncate lines={2} ellipsis={'...'} className={classes.text}>{item.title}</Truncate>
          </div>)}
        </div>
        <div className={classes.bottomContainer}>

          <Button
            className={classes.button}
            color="primary"
            href="https://www.facebook.com/qboard.tech"
            target="_blank"
          >
            {t('All news')}
          </Button>
        </div>
      </div>
    </div>
  </div>);
};

News.propTypes = {
  heights: PropTypes.object.isRequired,
  onLoad: PropTypes.func
};

export default News;

import React from 'react';
import PropTypes from 'prop-types';
import {createStyles, DialogContent, Icon, makeStyles} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import {useTranslation} from 'react-i18next';
import IconButton from "@material-ui/core/IconButton";
import { ReactComponent as CloseIcon } from './icons/close.svg'
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';


const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      height: '80%',
      overflowY: 'scroll',
      border: '1px solid #E0E0E0',
    },
    header: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& > div:first-child': {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
      },
      padding: `${theme.spacing(1)}px 0`
    },
    item: {},
    icon: {
      width: theme.spacing(3),
      fill: theme.palette.secondary.main,
      padding: 0
    }
  })
);

const TaskLogs = ({ items, open, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation()
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      onClose={onClose}
      open={open}
    >
      <DialogContent>
        <div className={classes.header}>
          <div>
            <Icon color="secondary"><ViewHeadlineIcon/></Icon>
            <Typography color="secondary" variant="h6">{t('performed log')}</Typography>
          </div>
          <IconButton
            onClick={onClose}
            className={classes.icon}
          >
            <CloseIcon/>
          </IconButton>
        </div>
        <div className={classes.root}>
          {items.map((s, i) => (
            <p
              className={classes.item}
              key={`log-${i}`}
            >
              {s}
            </p>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};

TaskLogs.defaultProps = {
  items: []
};

TaskLogs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default TaskLogs;

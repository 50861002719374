import * as CONSTS from './consts';

export const fetchAction = () => ({
  type: CONSTS.FETCH_LIST
});

export const createAction = ({
  name,
  label,
  def_value,
  helper_text,
  order,
  type,
  validators
}) => {
  return {
    type: CONSTS.FETCH_CREATE,
    payload: {
      name,
      label,
      def_value,
      helper_text,
      order,
      type,
      validators
    }
  };
};

export const deleteAction = id => ({
  type: CONSTS.DELETE,
  payload: { id }
});

export const updateAction = ({
  id,
  name,
  label,
  def_value,
  helper_text,
  order,
  type,
  validators
}) => {
  const data = {
    id,
    name,
    label,
    def_value,
    helper_text,
    order,
    type,
    validators
  };
  return {
    type: CONSTS.UPDATE,
    payload: { id, data }
  };
};

export const updateList = items => ({
  type: CONSTS.LIST,
  payload: [...items]
});

import { CircularProgress, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useAction } from '../utils/hooks';
import { activateEmailAction } from '../core/emailActivation/actions';
import {getLocation} from '../Routes';
import { useSelector } from 'react-redux';
import { emailActivationSelector } from '../core/emailActivation/selectors';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.main
  },
  loaderShow: {
    background: 'rgba(255, 255, 255, 0.8)',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  loaderHide: {
    display: 'none'
  }
}));

const EmailActivation = () => {
  const classes = useStyles()
  const history = useHistory()
  const activateEmail = useAction(activateEmailAction)
  const { payload, isFetching, error } = useSelector(emailActivationSelector)
  useEffect(() => {
    const {uid, token} = getLocation().getActivationData();
    activateEmail(uid, token);
  }, []);

  useEffect(() => {
    if (payload || error) history.push(getLocation().SignIn())
  }, [payload, error]);

  return (
    <div className={clsx(classes.loaderShow, !isFetching && classes.loaderHide)}>
      <CircularProgress />
    </div>
  )
};

export default EmailActivation;